var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "overlay-appear"
    },
    on: {
      "enter": function enter($event) {
        _vm.isContentVisible = true;
      },
      "after-leave": _vm.afterOverlayClose
    }
  }, [_vm.isOverlayVisible ? _c('div', {
    class: [_vm.$style.overlay, _vm.classes],
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onClose.apply(null, arguments);
      }
    }
  }, [_c(_vm.component, {
    tag: "component",
    attrs: {
      "data": _vm.options,
      "visible": _vm.isContentVisible
    },
    on: {
      "close": _vm.onClose,
      "after-enter": function afterEnter($event) {
        _vm.isOverflowing = true;
      },
      "before-leave": function beforeLeave($event) {
        _vm.isOverflowing = false;
      },
      "after-leave": _vm.afterContentClose
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };