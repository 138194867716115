// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error_AJ0cI{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;height:100%;justify-content:center}@media only screen and (max-width:424.98px){.Error_AJ0cI{padding:0 16px}}.Error_AJ0cI .button_kILPm{margin-bottom:20px}.title_1RRqX{color:#012345;font-size:8rem;font-weight:500;line-height:112%}@media only screen and (max-width:424.98px){.title_1RRqX{font-size:4.8rem}}.text_oPYlq{color:#012345;line-height:152%;margin-bottom:4rem;margin-top:1.6rem;max-width:31rem;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Error": "Error_AJ0cI",
	"button": "button_kILPm",
	"title": "title_1RRqX",
	"text": "text_oPYlq"
};
module.exports = ___CSS_LOADER_EXPORT___;
