import { useVuexStore } from '@/plugins/app-context';

// FIXME $auth и прочий стор на pinia
export const getUserRole = (): string => {
  const role = useVuexStore().getters.role as string | undefined;

  if (role == null) {
    throw new Error('User role was null');
  }

  return role;
};
