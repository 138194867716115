import { IPageScrollService } from '../interface';
import { getBodyScrollbarWidth } from '../utils';

export class PageScrollServiceOverflowClip implements IPageScrollService {
    private lockCount: number = 0

    lock (elements?: Array<HTMLElement>): void {
      if (!this.isLocked()) {
        this.setScrollOffset(getBodyScrollbarWidth());
        document.documentElement.style.overflow = 'clip';
      }

      this.lockCount += 1;
    }

    unlock (elements?: Array<HTMLElement>): void {
      this.lockCount = Math.max(0, this.lockCount - 1);

      if (!this.isLocked()) {
        this.setScrollOffset(0);
        // @ts-expect-error это удалит значение, нам не нужно ему какое-то другое значение давать
        document.documentElement.style.overflow = null;
      }
    }

    forceUnlock (): void {
      this.lockCount = 0;
      this.setScrollOffset(0);
      // @ts-expect-error это удалит значение, нам не нужно ему какое-то другое значение давать
      document.documentElement.style.overflow = null;
    }

    private setScrollOffset (offset: number): void {
      if (!process.client) {
        return;
      }

      document.documentElement.style.setProperty('--scroll-lock-offset', `${ offset }px`);
    }

    isLocked (): boolean {
      return this.lockCount > 0;
    }
}
