var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "container",
    class: _vm.$style.HeaderMenuItem
  }, [_c('div', {
    class: _vm.$style.header,
    on: {
      "click": _vm.handleToggle
    }
  }, [_vm._t("header"), _vm._v(" "), _c('span', {
    class: _vm.$style.arrow
  }, [_c('SIcon', {
    class: _vm.$style.arrowIcon,
    attrs: {
      "icon": "chevron-down",
      "size": "16"
    }
  })], 1)], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };