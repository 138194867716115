import type {
  ActionTree,
  MutationTree
} from 'vuex';

type StagesKeys =
  'ACCEPT_EVENT' |
  'REFUSE_EVENT' |
  'ACCEPT_EVENT_SUCCESS' |
  'REFUSE_EVENT_SUCCESS';

type Stage = {
  // eslint-disable-next-line no-unused-vars
  [_key in StagesKeys]: string;
}

const STAGES: Stage = {
  ACCEPT_EVENT: 'EventAction',
  REFUSE_EVENT: 'EventAction',
  ACCEPT_EVENT_SUCCESS: 'EventActionSuccess',
  REFUSE_EVENT_SUCCESS: 'EventActionSuccess'
};

interface EventType {
  label: string;
  value: string;
}

interface EventTag {
  name: string;
  color: string;
  textColor: string;
}

interface EventResponse {
  id: number;
  name: string;
  type: EventType;
  dateStart: Date;
  dateEnd?: Date;
  isParticipant: boolean;
  hasEmptySeats: boolean;
  tags: Array<EventTag>;
}

interface City {
  id: number;
  name: string;
  slug: string;
}

interface File {
  aws: string;
  proxy: string | null;
  s3: string;
  src: string;
}

interface EventDetailResponse extends EventResponse {
  description?: string;
  comment?: string;
  city?: City;
  address?: string;
  link?: string;
  image?: File;
  recordDateEnd?: Date;
  managerFullName: string;
  managerPhone: string;
  maxParticipantsCount?: number;
  participantsCount?: number;
}

interface QRCodeData {
  code: string;
  title: string;
  subtitle: string;
  eventDate: string;
  timeslot: string;
  text: string;
}

interface State {
  stateEventsAction?: string;
  event?: EventDetailResponse;
  qrCodeData: QRCodeData | null;
}

export const state = (): State => ({
  stateEventsAction: undefined,
  event: undefined,
  qrCodeData: null
});

/* eslint-disable */
export type RootState = any;

export const actions: ActionTree<State, RootState> = {
  async getEvent ({ commit }, id): Promise<void> {
    try {
      const url = this.$api.events.getEvent(id);
      const event = await this.$axios.$get<EventDetailResponse>(url);
      
      commit('SET_EVENT', event);
    } catch (error) {
      console.log('🚀 ~ file: events.ts ~ getEvent ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },
  
  async acceptEvent ({ commit }, id) {
    try {
      const url = this.$api.events.accept(id);
      const event = await this.$axios.$patch<EventDetailResponse>(url);
    
      commit('SET_EVENT', event);
    } catch (error) {
      console.log('🚀 ~ file: events.ts ~ acceptEvent ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },
  
  async refuseEvent ({ commit }, id): Promise<void> {
    try {
      const url = this.$api.events.refuse(id);
      const event = await this.$axios.$patch<EventDetailResponse>(url);
    
      commit('SET_EVENT', event);
    } catch (error) {
      console.log('🚀 ~ file: events.ts ~ refuseEvent ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },
  
  setState ({ commit }, stage): void {
    commit('SET_STAGE', stage);
  },
  
  async getQRCode ({ commit }) {
    try {
      const url = this.$api.events.getQRCode;
      const { data } = await this.$axios.get<QRCodeData | null>(url);
      
      if (data) {
        commit('SET_QR_CODE_DATA', data);
      } else {
        commit('SET_QR_CODE_DATA', null);
      }
    } catch (error) {
      console.log('🚀 ~ file: events.ts ~ getQRCode ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  resetQRCode ({ commit }) {
    commit('SET_QR_CODE_DATA', null);
  }
};

export const mutations: MutationTree<State> = {
  SET_STAGE (
    state: State,
    stage: StagesKeys
  ): void {
    state.stateEventsAction = STAGES[stage];
  },
  
  SET_EVENT (
    state: State,
    payload: EventDetailResponse
  ): void {
    state.event = payload;
  },
  
  SET_QR_CODE_DATA (state, payload: QRCodeData | null) {
    state.qrCodeData = payload;
  }
};
