import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.timers.js";
import { SButton } from '@strana-artw/ui-kit';
import { pageScrollService } from '@@/shared/services/PageScrollService';
var MAX_POPUP_WIDTH = 244;
var MIN_EDGE_OFFSET = 24;
var POPUP_OFFSET_TOP = 16;
var ESC_CODE = 27;
var closeTimeoutId = null;
var updateOuterValueTimeoutId = null;
export default {
  components: {
    SButton: SButton
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    parentRect: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    text: {
      type: String,
      default: 'Ваш персональный QR-код для доступа на презентацию нового ЖК'
    },
    buttonText: {
      type: String,
      default: 'Понятно'
    }
  },
  emits: ['accepted', 'update:opened'],
  data: function data() {
    return {
      innerOpened: false,
      animationDuration: 300
    };
  },
  computed: {
    cssVariables: function cssVariables() {
      var _this$parentRect = this.parentRect,
        top = _this$parentRect.top,
        left = _this$parentRect.left,
        height = _this$parentRect.height,
        width = _this$parentRect.width;
      var popupLeft = left + width / 2;
      var popupTop = top + height + POPUP_OFFSET_TOP;
      if (popupLeft + MAX_POPUP_WIDTH > window.innerWidth) {
        popupLeft = window.innerWidth - MAX_POPUP_WIDTH / 2 - MIN_EDGE_OFFSET;
      }
      return "\n        --popup-top: ".concat(popupTop, "px; \n        --popup-left: ").concat(popupLeft, "px;\n        --max-popup-width: ").concat(MAX_POPUP_WIDTH, "px;\n        --animation-duration: ").concat(this.animationDuration, "ms;\n      ");
    }
  },
  watch: {
    opened: function opened(newValue) {
      var _this = this;
      clearTimeout(updateOuterValueTimeoutId);
      if (!newValue) {
        pageScrollService.unlock();
        this.innerOpened = newValue;
      } else {
        pageScrollService.lock();
        updateOuterValueTimeoutId = setTimeout(function () {
          _this.innerOpened = newValue;
        }, this.animationDuration);
      }
    }
  },
  mounted: function mounted() {
    document.addEventListener('keydown', this.handleEscape);
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscape);
  },
  methods: {
    handleEscape: function handleEscape(event) {
      if (event.keyCode === ESC_CODE) {
        this.close();
      }
    },
    handleAccept: function handleAccept() {
      this.close('accepted');
    },
    close: function close() {
      var _this2 = this;
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'update:opened';
      clearInterval(closeTimeoutId);
      this.innerOpened = false;
      closeTimeoutId = setTimeout(function () {
        _this2.$emit(event, false);
      }, this.animationDuration);
    }
  }
};