import type {
  GetterTree,
  ActionTree,
  MutationTree
} from 'vuex';
import DOMPurify from 'isomorphic-dompurify';

interface Template {
  title?: string;
  text: string;
}

type TemplateObj = Record<string, Template>;

type Slug = Record<string, string>;

interface State {
  template: TemplateObj;
  slug: Slug;
}

export const state = (): State => ({
  template: {},
  slug: {
    AGENT_MODAL: 'agent_fire_modal',
    AGENT_JOIN: 'agent_join_agency',
    REPRES_MODAL: 'repres_fire_agent_modal',
    REPRES_SUCCESS: 'repres_fire_agent_success',
    EVENT_TEMPLATE: 'event_template',
    BOOKING_PROJECT: 'choice_residential_complex_description',
    BOOKING_SUCCESS: 'success_paid_booking_description',
    main_page_main_section: 'main_page_main_section',
    main_page_offer_section: 'main_page_offer_section',
    main_page_partner_section: 'main_page_partner_section',
    main_page_sell_online_section: 'main_page_sell_online_section',
    main_page_manager_section_title: 'main_page_manager_section_title',
    main_page_manager_section: 'main_page_manager_section'
  }
});

// eslint-disable-next-line
export type RootState = any;

export const getters: GetterTree<RootState, RootState> = {
  getSlug: (
    { slug }: State
  ) => (
    key: string
  ): string => slug[key],
  getTemplateBySlug: (
    { template }: State
  ) => (
    slug: string
  ): Template | undefined => template[slug]
};

export const actions: ActionTree<RootState, RootState> = {
  async getTemplate (
    { state, commit },
    { slug, agentId }
  ): Promise<void> {
    try {
      if (state.template?.[slug]) {
        return;
      }
      const url = this.$api.template.getTemplateBySlug(slug);
      const {
        data
      } = await this.$axios.get<Template>(url, {
        params: {
          agent_id: agentId
        }
      });

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const templateKey = key as keyof Template;

          if (data[templateKey]) {
            data[templateKey] = DOMPurify.sanitize(data[templateKey] as string, { USE_PROFILES: { html: true } });
          }
        }
      }

      commit('SET_TEMPLATE', {
        slug,
        data
      });
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('~ [template/getTemplate] ~ error', error);
      this.$sentry.captureException(error);
      const message = error?.response?.data?.message ||
        error?.message ||
        'Произошла ошибка';
      throw (message);
    }
  },

  async getSmsText (
    { commit },
    projectId: [string, number]
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const url = this.$api.template.getAssignSmsText;
      const {
        data
      } = await this.$axios.get<Template>(url, {
        params: {
          project_id: projectId
        }
      });
      commit('SET_TEMPLATE', {
        slug: 'SMS_TEXT',
        data
      });
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('~ [template/getSmsText] ~ error', error);
      this.$sentry.captureException(error);
      const message = error?.response?.data?.message ||
        error?.message ||
        'Произошла ошибка';
      throw (message);
    }
  },

  resetTemplate ({ commit }, slug) {
    commit('SET_TEMPLATE', {
      slug,
      undefined
    });
  }
};

export const mutations: MutationTree<RootState> = {
  SET_TEMPLATE (
    state,
    { slug, data }
  ): void {
    const template = { ...state.template };
    template[slug] = data;
    state.template = { ...template };
  }
};
