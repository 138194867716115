import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { prettyPhone } from '@strana-artw/cabinets-common';
import { SButton, SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'HeaderWithoutLogin',
  components: {
    SIcon: SIcon,
    SButton: SButton
  },
  props: {
    isLoginButtonActive: {
      type: Boolean,
      default: false
    },
    isBackLinkActive: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      phone: '+73452579440'
    };
  },
  computed: {
    link: function link() {
      return "//".concat(process.env.HOST);
    },
    preparedPhone: function preparedPhone() {
      return prettyPhone(this.phone);
    }
  }
};