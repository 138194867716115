/* unplugin-vue-components disabled */var render, staticRenderFns
import script from "./VList.vue?vue&type=script&lang=js"
export * from "./VList.vue?vue&type=script&lang=js"
import style0 from "./VList.vue?vue&type=style&index=0&id=17bbd98d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports