const documentsApi = {
  documents: {
    detail: (documentSlug) => `/api/documents/slug/${ documentSlug }`,
    getQuizDocuments: (slug, bookingId) => `/api/agents/questionnaire/${ slug }/groups_of_documents/${ bookingId }`,
    sendPackageDocuments: (bookingId) => `/api/agents/questionnaire/${ bookingId }/send`,
    deleteDocument: () => 'api/agents/questionnaire/upload-documents',
    getDocument: (role, id) => `/api/agencies/${ role }/agreements/${ id }/get_document`
  }
};

export default documentsApi;
