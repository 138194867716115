import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import {
  AgentProfile,
  AgencyProfile,
  AgencyProfileFile,
  PatchAgentProfileBody,
  ChangePasswordBody,
  Maintainer
} from './types';
import { LabelValue } from '@/types/Type';
import { CityResponse } from '@/types/Cities';

const STAGES = {
  EDIT_PROFILE: 'EditProfile',
  EDIT_PROFILE_AGENCY: 'EditProfileAgency',
  CHANGE_PASSWORD: 'ChangePassword',
  CHANGE_PASSWORD_SUCCESS: 'ChangePasswordSuccess',
  RESTORE_PASSWORD: 'RestorePassword',
  RESTORE_PASSWORD_SUCCESS: 'RestorePasswordSuccess',
  FIRED_CONFIRM: 'FiredConfirm',
  FIRED_SUCCESS: 'FiredSuccess'
} as const;

interface Agency {
  name: string;
  city: string;
  files: Array<AgencyProfileFile>;
  inn: string;
  type: LabelValue;
  maintainer: Maintainer;
}

type Profile = Omit<
  Partial<AgentProfile> & { password?: string; birthDate?: string; cityOfSale?: CityResponse },
  'city_of_sale' | 'birth_date'
>

export const useAgentProfileStore = defineStore('agentProfile', () => {
  const {
    $axios,
    $auth,
    $api,
    $router
  } = useContext();

  const profile = ref<Profile>({
    name: undefined,
    surname: undefined,
    patronymic: undefined,
    email: undefined,
    phone: undefined,
    agency: undefined,
    password: '*'.repeat(16),
    birthDate: undefined,
    cityOfSale: undefined
  });

  const agency = ref<Agency | undefined>(undefined);

  const stateProfile = ref<typeof STAGES[keyof typeof STAGES]>(STAGES.EDIT_PROFILE);

  async function getAccount (): Promise<void> {
    const url = `/api/${ $auth.$state.strategy }/profile`;
    const {
      name,
      surname,
      patronymic,
      email,
      phone,
      agency,
      birth_date: birthDate,
      city_of_sale: cityOfSale
    } = await $axios.$get<AgentProfile>(url);

    const preparedBirthDate = birthDate
      ? Intl.DateTimeFormat('ru-RU', { dateStyle: 'short' }).format(new Date(birthDate))
      : '';

    profile.value.name = name;
    profile.value.surname = surname;
    profile.value.patronymic = patronymic;
    profile.value.email = email;
    profile.value.phone = phone;
    profile.value.agency = agency;
    profile.value.birthDate = preparedBirthDate;
    profile.value.cityOfSale = cityOfSale;
  }

  async function getAgencyAccount (): Promise<void> {
    const url = `/api/agencies/${ $auth.$state.strategy }/profile`;
    const agencyResponse = await $axios.$get<AgencyProfile>(url);

    agency.value = agencyResponse;
  }

  async function updatePassword (payload: ChangePasswordBody): Promise<void> {
    const url = `/api/${ $auth.$state.strategy }/change_password`;
    await $axios.$patch(url, payload);
  }

  async function updateProfile (payload: PatchAgentProfileBody): Promise<void> {
    const url = `/api/${ $auth.$state.strategy }/profile`;
    await $axios.$patch(url, payload);
    await $auth.fetchUser();
  }

  async function resetPasswordByEmail (payload: { email: string }): Promise<void> {
    const url = `/api/${ $auth.$state.strategy }/email_reset`;
    await $axios.$post(url, payload);
  }

  async function updateAgency (payload: {
    name: string;
    'procuratory_files': Array<unknown>;
    'company_files': Array<unknown>;
  }): Promise<void> {
    await $axios.$patch('/api/agencies/profile', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    await getAgencyAccount();
  }

  async function joinAgency (inn: string): Promise<void> {
    const { strategy } = $auth.$state;
    const url = $api.account.joinAgency(strategy);
    await $axios.$post(url, {
      agencyInn: inn
    });

    if (strategy === 'represes') {
      await $router.push({ query: {} });
      await $auth.logout();
    }
  }

  function setStage (stage: keyof typeof STAGES): void {
    stateProfile.value = STAGES[stage];
  }

  const decoratedNoRethrow = decorateApiActions({
    getAccount,
    getAgencyAccount,
    resetPasswordByEmail
  }, 'agentProfile');

  const decoratedRethrow = decorateApiActions({
    updatePassword,
    updateProfile,
    updateAgency,
    joinAgency
  }, 'agentProfile', true);

  return {
    profile,
    agency,
    stateProfile,
    ...decoratedNoRethrow,
    ...decoratedRethrow,
    setStage
  };
});
