import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';

let nuxtApp: Context | undefined;

export default defineNuxtPlugin((_nuxtApp: Context) => {
  nuxtApp = _nuxtApp;
});

export const useSentry = (): NonNullable<typeof nuxtApp>['$sentry'] => {
  if (!nuxtApp?.$sentry) {
    throw new Error('Sentry is not defined :(');
  }

  return nuxtApp?.$sentry;
};
