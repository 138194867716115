import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
export default {
  name: 'SimpleOverlay',
  beforeMount: function beforeMount() {
    document.addEventListener('keyup', this.closedByEscKey);
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('keyup', this.closedByEscKey);
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('clicked');
    },
    closedByEscKey: function closedByEscKey(event) {
      if (event.code === 'Escape') {
        this.handleClick();
      }
    }
  }
};