// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopNotification_huHEj{border-bottom:1px solid #ccd3da;display:-webkit-box;display:-ms-flexbox;display:flex;min-height:41px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 58px}@media only screen and (max-width:1023.98px){.TopNotification_huHEj{padding:0 40px}}@media only screen and (max-width:767.98px){.TopNotification_huHEj{padding:0 20px}}.TopNotification_huHEj .btn_1sjqE{border-radius:50%;display:-webkit-box;display:-ms-flexbox;display:flex;height:2.4rem;width:2.4rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.message_JftWJ{-webkit-box-flex:1;-ms-flex:1;flex:1;font-size:16px;font-weight:400;line-height:1.56;word-break:break-word}@media only screen and (max-width:767.98px){.message_JftWJ{font-size:12px}}.warning_i0fpM{background:-webkit-gradient(linear,left top,right top,from(#92278f),color-stop(57.99%,#c6168d),to(#ed1c24));background:linear-gradient(90deg,#92278f,#c6168d 57.99%,#ed1c24)}.warning_i0fpM .message_JftWJ{color:#fcfcfc}.information_Vs5aJ{background:#ebeef0}.information_Vs5aJ .message_JftWJ{color:#012345}.leftIcon_l8f-u{margin-right:14px}.leftIcon_l8f-u .btn_1sjqE.btn-information_II26a,.leftIcon_l8f-u .btn_1sjqE.btn-warning_uPWSC{background:#fcfcfc}.rightIcon_hVrXX{margin-left:14px}.rightIcon_hVrXX .btn_1sjqE.btn-warning_uPWSC{background:hsla(0,0%,100%,.5)}.rightIcon_hVrXX .btn_1sjqE.btn-information_II26a{background:#ccd3da}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TopNotification": "TopNotification_huHEj",
	"btn": "btn_1sjqE",
	"message": "message_JftWJ",
	"warning": "warning_i0fpM",
	"information": "information_Vs5aJ",
	"leftIcon": "leftIcon_l8f-u",
	"btn-information": "btn-information_II26a",
	"btn-warning": "btn-warning_uPWSC",
	"rightIcon": "rightIcon_hVrXX"
};
module.exports = ___CSS_LOADER_EXPORT___;
