// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QRCode_bkHLV{cursor:pointer;display:-webkit-box;display:-ms-flexbox;display:flex;height:4rem;width:4rem;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:.6rem;position:relative}@media only screen and (max-width:424.98px){.QRCode_bkHLV{height:32px;width:32px}.QRIcon_RmO7e svg{height:20px!important;min-width:0!important;min-width:auto!important;width:20px!important}}.withPopup_A3Hxz{background:#fff;border-radius:50%;z-index:3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QRCode": "QRCode_bkHLV",
	"QRIcon": "QRIcon_RmO7e",
	"withPopup": "withPopup_A3Hxz"
};
module.exports = ___CSS_LOADER_EXPORT___;
