import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.wrapper
  }, [_c('SIcon', {
    attrs: {
      "icon": "user",
      "size": "18"
    }
  }), _vm._v(" "), _c('div', {
    class: [_vm.$style.textWrapper, _vm.$style.hideMobile]
  }, [_c('div', {
    class: _vm.$style.text
  }, [_vm._v("\n      " + _vm._s(_vm.user.name) + "\n    ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.role
  }, [_vm._v("\n      " + _vm._s(_vm.user.role) + "\n    ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };