// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuMobileHead_i0Gwx{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;background-color:#fafafa;-ms-flex-direction:column;flex-direction:column;gap:.8rem;padding:3.2rem 1.6rem}.MenuMobileHead_i0Gwx .bottomLine_Cszqy,.MenuMobileHead_i0Gwx .topLine_PzD0K{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:.8rem;justify-content:space-between}.button_ZTKUP{padding-left:0;padding-right:0;-ms-flex-preferred-size:14.5rem;flex-basis:14.5rem;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}@media only screen and (max-width:767.98px){.supportButton_7n-Ky{-ms-flex-preferred-size:100%;flex-basis:100%}}.certifiedPartner_ziXlN{-ms-flex-preferred-size:100%;flex-basis:100%}.modalWrapper_7Iyh8{background-color:#012345;padding-bottom:.8rem;padding-top:7.2rem}.ContactsContent_EX0q-{--manager-padding:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuMobileHead": "MenuMobileHead_i0Gwx",
	"bottomLine": "bottomLine_Cszqy",
	"topLine": "topLine_PzD0K",
	"button": "button_ZTKUP",
	"supportButton": "supportButton_7n-Ky",
	"certifiedPartner": "certifiedPartner_ziXlN",
	"modalWrapper": "modalWrapper_7Iyh8",
	"ContactsContent": "ContactsContent_EX0q-"
};
module.exports = ___CSS_LOADER_EXPORT___;
