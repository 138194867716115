import {
  chunk,
  padEnd
} from './helpers';

export function isCssColor (color = false) {
  return Boolean(color) && Boolean(color.match(/^(#|var\(--|(rgb|hsl)a?\()/));
}

export function classToHex (
  color,
  colors,
  currentTheme
) {
  const [colorName, colorModifier] = color
    .toString().trim()
    .replace('-', '')
    .split(' ', 2);

  let hexColor = '';

  if (colorName && colorName in colors) {
    if (colorModifier && colorModifier in colors[colorName]) {
      hexColor = colors[colorName][colorModifier];
    } else if ('base' in colors[colorName]) {
      hexColor = colors[colorName].base;
    }
  } else if (colorName && colorName in currentTheme) {
    hexColor = currentTheme[colorName];
  }

  return hexColor;
}

export function HexToRGBA (hex) {
  const rgba = chunk(hex.slice(1), 2).map((c) => parseInt(c, 16));

  return {
    r: rgba[0],
    g: rgba[1],
    b: rgba[2],
    a: Math.round(rgba[3] / 255 * 100) / 100
  };
}

export function parseHex (hex) {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  hex = hex.replace(/([^0-9a-f])/gi, 'F');

  if (hex.length === 3 || hex.length === 4) {
    hex = hex.split('').map((x) => x + x)
      .join('');
  }

  if (hex.length === 6) {
    hex = padEnd(hex, 8, 'F');
  } else {
    hex = padEnd(padEnd(hex, 6), 8, 'F');
  }

  return `#${ hex }`.toUpperCase().substr(0, 9);
}

export function parseGradient (
  gradient,
  colors,
  currentTheme
) {
  return gradient.replace(/([a-z]+(\s[a-z]+-[1-5])?)(?=$|,)/gi, (x) => classToHex(x, colors, currentTheme) || x).replace(/(rgba\()#[0-9a-f]+(?=,)/gi, (x) => 'rgba(' + Object.values(HexToRGBA(parseHex(x.replace(/rgba\(/, '')))).slice(0, 3)
    .join(','));
}
