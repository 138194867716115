import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../lk_broker/store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../lk_broker/store/account.ts'), 'account.ts')
  resolveStoreModules(require('../lk_broker/store/acts.ts'), 'acts.ts')
  resolveStoreModules(require('../lk_broker/store/additionalAgreement.ts'), 'additionalAgreement.ts')
  resolveStoreModules(require('../lk_broker/store/agencies.ts'), 'agencies.ts')
  resolveStoreModules(require('../lk_broker/store/authActions.ts'), 'authActions.ts')
  resolveStoreModules(require('../lk_broker/store/authStore.ts'), 'authStore.ts')
  resolveStoreModules(require('../lk_broker/store/cities.ts'), 'cities.ts')
  resolveStoreModules(require('../lk_broker/store/client.ts'), 'client.ts')
  resolveStoreModules(require('../lk_broker/store/clients.ts'), 'clients.ts')
  resolveStoreModules(require('../lk_broker/store/contract.ts'), 'contract.ts')
  resolveStoreModules(require('../lk_broker/store/deal.ts'), 'deal.ts')
  resolveStoreModules(require('../lk_broker/store/deals.js'), 'deals.js')
  resolveStoreModules(require('../lk_broker/store/discount.ts'), 'discount.ts')
  resolveStoreModules(require('../lk_broker/store/events.ts'), 'events.ts')
  resolveStoreModules(require('../lk_broker/store/featureFlags.ts'), 'featureFlags.ts')
  resolveStoreModules(require('../lk_broker/store/fired.ts'), 'fired.ts')
  resolveStoreModules(require('../lk_broker/store/flat.ts'), 'flat.ts')
  resolveStoreModules(require('../lk_broker/store/header.js'), 'header.js')
  resolveStoreModules(require('../lk_broker/store/helpManager.ts'), 'helpManager.ts')
  resolveStoreModules(require('../lk_broker/store/images.js'), 'images.js')
  resolveStoreModules(require('../lk_broker/store/interaction.ts'), 'interaction.ts')
  resolveStoreModules(require('../lk_broker/store/loyalty.ts'), 'loyalty.ts')
  resolveStoreModules(require('../lk_broker/store/mainPage.ts'), 'mainPage.ts')
  resolveStoreModules(require('../lk_broker/store/manuals.ts'), 'manuals.ts')
  resolveStoreModules(require('../lk_broker/store/meetings.ts'), 'meetings.ts')
  resolveStoreModules(require('../lk_broker/store/menu.ts'), 'menu.ts')
  resolveStoreModules(require('../lk_broker/store/notification.ts'), 'notification.ts')
  resolveStoreModules(require('../lk_broker/store/onboarding.ts'), 'onboarding.ts')
  resolveStoreModules(require('../lk_broker/store/projects.ts'), 'projects.ts')
  resolveStoreModules(require('../lk_broker/store/property.ts'), 'property.ts')
  resolveStoreModules(require('../lk_broker/store/registration.ts'), 'registration.ts')
  resolveStoreModules(require('../lk_broker/store/survey.js'), 'survey.js')
  resolveStoreModules(require('../lk_broker/store/templates.ts'), 'templates.ts')
  resolveStoreModules(require('../lk_broker/store/calendar/index.ts'), 'calendar/index.ts')
  resolveStoreModules(require('../lk_broker/store/buildings/index.ts'), 'buildings/index.ts')
  resolveStoreModules(require('../lk_broker/store/agentProfile/index.ts'), 'agentProfile/index.ts')
  resolveStoreModules(require('../lk_broker/store/additionalAgreements/index.ts'), 'additionalAgreements/index.ts')
  resolveStoreModules(require('../lk_broker/store/acts/acts.ts'), 'acts/acts.ts')
  resolveStoreModules(require('../lk_broker/store/acts/types.ts'), 'acts/types.ts')
  resolveStoreModules(require('../lk_broker/store/agentProfile/agentProfile.ts'), 'agentProfile/agentProfile.ts')
  resolveStoreModules(require('../lk_broker/store/agentProfile/types.ts'), 'agentProfile/types.ts')
  resolveStoreModules(require('../lk_broker/store/agents/agents.ts'), 'agents/agents.ts')
  resolveStoreModules(require('../lk_broker/store/agents/types.ts'), 'agents/types.ts')
  resolveStoreModules(require('../lk_broker/store/buildings/buildings.ts'), 'buildings/buildings.ts')
  resolveStoreModules(require('../lk_broker/store/buildings/types.ts'), 'buildings/types.ts')
  resolveStoreModules(require('../lk_broker/store/calendar/filters.ts'), 'calendar/filters.ts')
  resolveStoreModules(require('../lk_broker/store/deal/booking.js'), 'deal/booking.js')
  resolveStoreModules(require('../lk_broker/store/deal/documents.js'), 'deal/documents.js')
  resolveStoreModules(require('../lk_broker/store/deals/dealsPinia.ts'), 'deals/dealsPinia.ts')
  resolveStoreModules(require('../lk_broker/store/deals/types.ts'), 'deals/types.ts')
  resolveStoreModules(require('../lk_broker/store/deals/utils.ts'), 'deals/utils.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
