import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.string.trim.js";
import Vue from 'vue';
export default Vue.extend({
  name: 'VListItemIcon',
  functional: true,
  render: function render(h, _ref) {
    var data = _ref.data,
      children = _ref.children;
    data.staticClass = "v-list-item__icon ".concat(data.staticClass || '').trim();
    return h('div', data, children);
  }
});