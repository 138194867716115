import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('button', {
    ref: "QRButton",
    class: [_vm.$style.QRCode, _defineProperty({}, _vm.$style.withPopup, _vm.isPopupOpen)],
    on: {
      "click": _vm.handleQRButtonClick
    }
  }, [_c('SIcon', {
    class: _vm.$style.QRIcon,
    attrs: {
      "icon": "i-qr-code",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c('QRCodeModal', {
    attrs: {
      "qr-code-data": _vm.qrCodeData
    },
    model: {
      value: _vm.isQRModalOpen,
      callback: function callback($$v) {
        _vm.isQRModalOpen = $$v;
      },
      expression: "isQRModalOpen"
    }
  }), _vm._v(" "), _vm.qrCodeNotification ? _c('HeaderQRCodePopup', {
    ref: "popup",
    attrs: {
      "text": _vm.qrCodeNotification.message,
      "button-text": _vm.qrCodeNotification.buttonText,
      "opened": _vm.isPopupOpen,
      "parent-rect": _vm.buttonRect
    },
    on: {
      "update:opened": function updateOpened($event) {
        _vm.isPopupOpen = $event;
      },
      "accepted": _vm.handlePopupClick
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };