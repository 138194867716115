// Styles
import './VHover.scss';

// Utilities
import { consoleWarn } from '../../utils/console';

function isTouchEvent (e) {
  return e.constructor.name === 'TouchEvent';
}
/* eslint-disable no-unused-expressions */
const calculate = (
  e,
  el
) => {
  let localX = 0;
  let localY = 0;

  const offset = el.getBoundingClientRect();
  const target = e;

  localX = target.clientX - offset.left;
  localY = target.clientY - offset.top;

  let radius = 0;
  let scale = 0.3;

  if (el._hover && el._hover.circle) {
    scale = 0.15;
    radius = el.clientWidth / 2;
    radius = el._hover.center ? radius : radius + Math.sqrt((localX - radius) ** 2 + (localY - radius) ** 2) / 4;
  } else {
    radius = Math.sqrt(el.clientWidth ** 2 + el.clientHeight ** 2) / 2;
  }

  const centerX = `${ el.clientWidth / 2 }`;
  const centerY = `${ el.clientHeight / 2 }`;

  const x = el._hover.center ? centerX : `${ localX }`;
  const y = el._hover.center ? centerY : `${ localY }`;

  return { radius, scale, x, y, centerX, centerY };
};

function setPosition (e, el) {
  if (!el._hover.animation) {
    return;
  }
  const { radius, scale, x, y } = calculate(e, el);
  el._hover.animation.style.top = `${ y }px`;
  el._hover.animation.style.left = `${ x }px`;

  return { radius, scale, x, y };
}
const hovers = {
  /* eslint-disable max-statements */
  show (
    e,
    el
  ) {
    if (!el._hover || !el._hover.enabled) {
      return;
    }
    let container; let animation;

    if (el._hover.wasEnabled && el._hideTimer) {
      clearTimeout(el._hideTimer);

      container = el._hover.container;
      animation = el._hover.animation;
    } else {
      container = document.createElement('span');
      animation = document.createElement('span');
      container.appendChild(animation);
      container.className = 'v-hover__container';
      animation.className = 'v-hover__animation';
      el._hover.container = container;
      el._hover.animation = animation;
      el.prepend(container);
    }
    el.classList.add('v-hover__wrapper');

    if (el._hover.class) {
      for (const [key, val] of Object.entries(el._hover.class)) {
        if (val) {
          animation.className += ` ${ key }`;
        }
      }
    }

    if (el._hover.style) {
      for (const [key, val] of Object.entries(el._hover.style)) {
        animation.style[key] = val;
      }
    }
    const { radius, scale } = setPosition(e, el);

    animation.style.width = '1px';
    animation.style.height = '1px';
    animation.classList.add('v-hover__animation--enter');
    animation.classList.add('v-hover__animation--visible');
    animation.style.boxShadow = `0 0 0 ${ radius * scale }px var(--hoverColor)`;


    setTimeout(() => {
      animation.classList.remove('v-hover__animation--enter');
      animation.classList.add('v-hover__animation--in');
      animation.style.boxShadow = `0 0 0 ${ radius * 2 }px var(--hoverColor)`;

      if (el?._hover) {
        el._hover.wasEnabled = true;
      }
    }, 0);
  },

  hide (e, el) {
    if (!el?._hover?.enabled || !el?._hover?.wasEnabled || !el?._hover?.animation) {
      return;
    }

    setPosition(e, el);
    const { animation } = el._hover;
    animation.classList.remove('v-hover__animation--in');
    animation.classList.add('v-hover__animation--out');
    animation.style.boxShadow = '0 0 0 0px currentColor';

    const hideTimer = setTimeout(() => {
      deleteHovers(el);
      el.classList.remove('v-hover__wrapper');

      if (el._hover?.wasEnabled) {
        el._hover.wasEnabled = false;
      }
    }, 400);
    el._hideTimer = hideTimer;
  }
};

function deleteHovers (el) {
  const hovers = el.querySelectorAll('.v-hover__container');

  if (hovers) {
    for (const hover of hovers) {
      hover.remove();
    }
  }
}

function isHoverEnabled (value) {
  return typeof value === 'undefined' || Boolean(value);
}

function hoverShow (e) {
  const element = e.currentTarget;

  if (isTouchEvent(e)) {
    return;
  }

  if (!element || !element._hover) {
    return;
  }

  hovers.show(e, element);
}

function hoverHide (e) {
  const element = e.currentTarget || null;

  if (!element) {
    return;
  }

  hovers.hide(e, element);
}

function updateHover (el, binding, wasEnabled) {
  const enabled = isHoverEnabled(binding.value);

  // || isTouchDeviceconst isTouchDevice = 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
  if (!enabled) {
    return deleteHovers(el);
  }
  el._hover = el._hover || {};
  el._hover.enabled = enabled;
  const value = binding.value || {};

  if (value.class) {
    el._hover.class = binding.value.class;
  }

  if (value.style) {
    el._hover.style = binding.value.style;
  }

  if (value.circle || value.center) {
    el._hover.center = value.circle;
  }

  if (enabled && !wasEnabled) {
    el.addEventListener('mouseenter', hoverShow, { passive: true });
    el.addEventListener('mouseleave', hoverHide);
  } else if (!enabled && wasEnabled) {
    removeListeners(el);
  }
}

function removeListeners (el) {
  el.removeEventListener('mouseenter', hoverShow, { passive: true });

  el.removeEventListener('mouseleave', hoverHide);
}

function directive (el, binding, node) {
  const hasTouch = node.context?.$device?.hasTouch;

  if (hasTouch) {
    return;
  }
  updateHover(el, binding, false);

  if (process.env.NODE_ENV === 'development') {
    // warn if an inline element is used, waiting for el to be in the DOM first
    if (node.context) {
      node.context.$nextTick(() => {
        const computed = window.getComputedStyle(el);

        if (computed && computed.display === 'inline') {
          const context = node.fnOptions ? [node.fnOptions, node.context] : [node.componentInstance];
          consoleWarn('v-hover can only be used on block-level elements', ...context);
        }
      });
    }
  }
}

function unbind (el) {
  delete el._hover;
  removeListeners(el);
}

function update (el, binding) {
  if (binding.value === binding.oldValue) {
    return;
  }

  const wasEnabled = isHoverEnabled(binding.oldValue);
  updateHover(el, binding, wasEnabled);
}

export const Hover = {
  bind: directive,
  unbind,
  update
};

export default Hover;
