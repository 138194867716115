import {
  useSentry
} from '@@/lk_broker/plugins/sentry';
import {
  type LogLevels,
  ILogger
} from '../interface';
import { BaseLogger } from './baseLogger';

export class SentryLogger extends BaseLogger implements ILogger {
  log (level: LogLevels, data: Error | string | unknown): void {
    if (!this.isEnabled(level)) {
      return;
    }

    const sentry = useSentry();
    switch (level) {
      case 'error': {
        sentry.captureException(data);

        return;
      }
    }

    console.error(`level ${ level } logging is not implemented`);
  }
}
