import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('BaseModal', {
    class: _vm.$style.QRCodeModal,
    attrs: {
      "open": _vm.isOpen,
      "backdrop-close": ""
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isOpen = $event;
      },
      "close": _vm.closeModal
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('button', {
          class: _vm.$style.close,
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.closeModal.apply(null, arguments);
            }
          }
        }, [_c('SIcon', {
          attrs: {
            "icon": "close",
            "size": "16",
            "color": "#012345"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          class: _vm.$style.content
        }, [_c('div', {
          class: _vm.$style.title
        }, [_vm._v("\n        " + _vm._s(_vm.qrCodeData.title) + "\n      ")]), _vm._v(" "), _c('div', {
          class: _vm.$style.description
        }, [_vm._v("\n        " + _vm._s(_vm.qrCodeData.subtitle) + "\n        "), _c('span', {
          class: _vm.$style.dateTime
        }, [_c('DateOutput', {
          attrs: {
            "input": _vm.qrCodeData.eventDate,
            "date-time-format-options": _vm.dateFormatOptions
          }
        }), _vm._v(" "), _vm.qrCodeData.timeslot ? _c('span', [_vm._v("\n            " + _vm._s(_vm.qrCodeData.timeslot) + "\n          ")]) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
          class: _vm.$style.codeWrapper
        }, [_c('div', {
          class: _vm.$style.code
        }, [_c('QRCodeCanvas', {
          attrs: {
            "content": _vm.qrCodeData.code
          }
        })], 1)]), _vm._v(" "), _vm.qrCodeData.text ? _c('p', {
          class: _vm.$style.text
        }, [_vm._v("\n        " + _vm._s(_vm.qrCodeData.text) + "\n      ")]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('SButton', {
          class: _vm.$style.button,
          attrs: {
            "theme": "secondary"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v("\n      Закрыть\n    ")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };