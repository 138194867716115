import { useMemoize } from '@vueuse/core';
import { PageScrollServiceIOS } from './implementations/ios-impl';
import { PageScrollServiceOverflowClip } from './implementations/through-overflow-clip';
import { IPageScrollService } from './interface';

const isIOS = useMemoize((): boolean => {
  if (typeof navigator === 'undefined') {
    return false;
  }

  return /iPad|iPhone|iPod/.test(navigator.userAgent);
});


export const pageScrollService: IPageScrollService = isIOS() ? new PageScrollServiceIOS() : new PageScrollServiceOverflowClip();
