export const state = () => ({

  questions: [],
  questionsIds: [],

  activeQuestionIndex: 0,
  currentQuestion: {},
  answers: {},
  history: [],
  currentStep: 0
});

export const actions = {
  async getQuestions ({ commit }, { bookingId, slug }) {
    try {
      const url = this.$api.quiz.getQuiz(slug);
      const questions = await this.$axios.$get(url, {
        params: {
          booking_id: bookingId
        }
      });
      commit('SET_DEFAULT_STATE');
      commit('SET_QUESTIONS', questions);
    } catch (error) {
      console.log('🚀 ~ file: survey.js ~ getQuestions ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  nextQuestion ({ commit, state }, answer) {
    const {
      id,
      options
    } = state.currentQuestion; // текущий вопрос

    commit('SET_QUESTIONS_HISTORY', { // добавляем вопрос в историю
      id,
      action: 'next'
    });

    const currentOption = options.find((option) => { // выбранный ответ
      if (Array.isArray(answer)) {
        return option.id === answer[0];
      }

      return option.id === answer;
    });

    const nextQuestion = state.questions
      .find((question) => question.id === currentOption.nextQuestion);

    if (nextQuestion) {
      commit('SET_CURRENT_QUESTION', nextQuestion);

      const nextQuestionIndex = state.questionsIds
        .indexOf(nextQuestion.id);
      commit('SET_ACTIVE_INDEX', nextQuestionIndex);
    } else {
      const currentQuestionIndex = state.questionsIds
        .indexOf(id);
      commit('SET_CURRENT_QUESTION', state.questions[currentQuestionIndex + 1]);
      commit('SET_ACTIVE_INDEX', currentQuestionIndex + 1);
    }
  },

  previousQuestion ({ commit, state }) {
    const lastQuestionId = state.history.at(-1) ?? 0;
    const lastQuestionIndex = state.questionsIds.indexOf(lastQuestionId);

    commit('SET_ACTIVE_INDEX', lastQuestionIndex);

    const prevQuestion = state.questions
      .find((question) => question.id === lastQuestionId);

    commit('SET_CURRENT_QUESTION', prevQuestion);
    commit('SET_QUESTIONS_HISTORY', {
      action: 'previous'
    });
  },

  async setActiveQuestionAnswer ({ commit, state }, { answer, bookingId }) {
    try {
      const {
        id
      } = state.currentQuestion;
      const url = this.$api.quiz.sendQuestion(id);
      await this.$axios.$patch(url, {
        option: answer,
        bookingId: Number(bookingId)
      });

      commit('SET_ANSWER', {
        id,
        answer
      });
    } catch (error) {
      console.log('🚀 ~ file: survey.js ~ setActiveQuestionAnswer ~ error', error);
      this.$sentry.captureException(error);
      throw new Error(error);
    }
  },

  async setFinishedQuiz (_, { bookingId, slug }) {
    try {
      const url = this.$api.quiz.finishedQuiz(slug);
      await this.$axios.$put(url, {
        bookingId: Number(bookingId)
      });
    } catch (error) {
      console.log('🚀 ~ file: survey.js ~ setFinishedQuiz ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  setStep ({ commit }, step) {
    commit('SET_STEP', step);
  }
};

export const mutations = {
  SET_QUESTIONS (state, payload) {
    state.questions = payload;
    state.questionsIds = payload.map((question) => question.id);
    state.currentQuestion = payload[0];
  },

  SET_ACTIVE_INDEX (state, payload) {
    state.activeQuestionIndex = payload;
  },

  SET_CURRENT_QUESTION (state, payload) {
    state.currentQuestion = payload;
  },

  SET_ANSWER (state, { id, answer }) {
    state.answers[id] = answer;
  },

  SET_DEFAULT_STATE (state) {
    state.questions = [];
    state.activeQuestionIndex = 0;
    state.currentQuestion = undefined;
    state.answers = {};
  },

  SET_QUESTIONS_HISTORY (state, { id, action }) {
    if (action === 'next') {
      state.history.push(id);
    }

    if (action === 'previous') {
      state.history.pop();
    }
  },

  SET_STEP (state, payload) {
    state.currentStep = payload;
  }
};
