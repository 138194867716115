// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NFooter_bGv\\+Z{background-color:#fafafa}.wrapper_eapKh{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;height:6.8rem;justify-content:space-between;padding:0 2.4rem}@media only screen and (max-width:767.98px){.wrapper_eapKh{padding:0 16px}}.text_HYXDM{color:#677b8f;font-size:1.4rem;font-weight:400;line-height:1.32}.logo_0huAJ{height:2rem;width:16.8rem}.columnWrapper_DBoKt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NFooter": "NFooter_bGv+Z",
	"wrapper": "wrapper_eapKh",
	"text": "text_HYXDM",
	"logo": "logo_0huAJ",
	"columnWrapper": "columnWrapper_DBoKt"
};
module.exports = ___CSS_LOADER_EXPORT___;
