import type {
  GetterTree,
  ActionTree,
  MutationTree
} from 'vuex';

interface Image {
  src: string;
  s3: string;
  aws: string;
  proxy?: string;
}

interface ContentItem {
  image: Image;
  description: string;
  title: string;
}

interface Content {
  sellOnline?: Array<ContentItem>;
  partnerLogo?: Array<ContentItem>;
  offer?: Array<ContentItem>;
}

interface Manager {
  lastname: string;
  name: string;
  patronymic: string;
  phone: string;
  photo: Image;
  position: string;
  workSchedule: string;
}
interface State {
  content: Content;
  manager?: Manager;
}

export const state = (): State => ({
  content: {},
  manager: undefined
});

export type RootState = never;

export const getters: GetterTree<State, RootState> = {};

export const actions: ActionTree<State, RootState> = {
  async getSellOnline ({ commit }) {
    try {
      const url: string = 'api/broker/main_page/content/sell_online';
      const { data } = await this.$axios.get<Array<ContentItem>>(url);
      commit('SET_CONTENT', { sellOnline: data });
    } catch (error) {
      console.error('🚀 ~ file: maniPage.ts ~ getSellOnline ~ e', error);
      this.$sentry.captureException(error);
    }
  },

  async getPartnerLogo ({ commit }) {
    try {
      const url: string = 'api/broker/main_page/content/partner_logo';
      const { data } = await this.$axios.get<Array<ContentItem>>(url);
      commit('SET_CONTENT', { partnerLogo: data });
    } catch (error) {
      console.error('🚀 ~ file: maniPage.ts ~ getPartnerLogo ~ e', error);
      this.$sentry.captureException(error);
    }
  },

  async getOffer ({ commit }) {
    try {
      const url: string = 'api/broker/main_page/content/offer';
      const { data } = await this.$axios.get<Array<ContentItem>>(url);
      commit('SET_CONTENT', { offer: data });
    } catch (error) {
      console.error('🚀 ~ file: maniPage.ts ~ getOffer ~ e', error);
      this.$sentry.captureException(error);
    }
  },

  async getManager ({ commit }) {
    try {
      const url: string = 'api/managers/head_of_partners_department';
      const { data } = await this.$axios.get<Array<Manager>>(url);
      commit('SET_MANAGER', data);
    } catch (error) {
      console.error('🚀 ~ file: maniPage.ts ~ getManager ~ e', error);
      this.$sentry.captureException(error);
    }
  }
};

export const mutations: MutationTree<State> = {
  SET_MANAGER (
    state: State,
    payload: Manager
  ): void {
    state.manager = payload;
  },

  SET_CONTENT (
    state: State,
    payload: ContentItem
  ): void {
    state.content = {
      ...state.content,
      ...payload
    };
  }
};
