export default {
  name: 'IconArtwLogo',
  props: {
    isWhiteW: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wColor: function wColor() {
      if (this.isWhiteW) {
        return '#FCFCFC';
      }
      return '#DC2655';
    }
  }
};