var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('nav', {
    class: _vm.$style.MenuMobileHead,
    attrs: {
      "aria-label": "Дополнительная"
    }
  }, [_c('div', {
    class: _vm.$style.topLine
  }, [_c(_setup.HeaderProfileButton, {
    attrs: {
      "dropdown-align": "start"
    }
  }), _vm._v(" "), _c(_setup.HelpButton, {
    attrs: {
      "href": "/faq"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.emit('menu-item-click');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.bottomLine
  }, [_c(_setup.HeaderButton, {
    class: _vm.$style.button,
    attrs: {
      "icon": "i-contacts-book-fill",
      "text": "Контакты"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.onContactsOpen.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _setup.hasSupportFlag ? _c(_setup.HeaderButton, {
    class: [_vm.$style.button, _vm.$style.supportButton],
    attrs: {
      "icon": "i-service-gradient",
      "text": "Техническая помощь"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.handleSupport.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm._v(" "), _c(_setup.CertifiedPartner, {
    class: [_vm.$style.button, _vm.$style.certifiedPartner]
  })], 1), _vm._v(" "), _setup.screen.isMobileOrTablet && _setup.contactsOpen ? _c(_setup.SModal, {
    attrs: {
      "type": "bottom-popup",
      "modal-key": "contacts"
    },
    on: {
      "close": _setup.onContactsClose
    }
  }, [_c('div', {
    class: _vm.$style.modalWrapper
  }, [_c(_setup.ContactsContent, {
    class: _vm.$style.ContactsContent
  })], 1)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };