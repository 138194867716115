import { defineStore } from 'pinia';
import { useOnboardingStore } from '@/store/onboarding';
export const useAuthActionsStore = defineStore('authActions', () => {
  const { getNotifications } = useOnboardingStore();

  // eslint-disable-next-line
  async function executeLoginActions (store: any): Promise<void> {
    if (!store.state.events.qrCodeData) {
      await store.dispatch('events/getQRCode');
    }

    if (process.client) {
      await getNotifications();
    }
  }

  // eslint-disable-next-line
  async function executeLogoutActions (store: any): Promise<void> {
    await store.dispatch('events/resetQRCode');
  }

  return {
    executeLoginActions,
    executeLogoutActions
  };
});
