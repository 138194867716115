import { defineStore } from 'pinia';

interface NotificationRaw {
  id: number;
  message: string;
  slug: string;
  // eslint-disable-next-line camelcase
  button_text: string;
}

interface Notification {
  id: number;
  message: string;
  slug: string;
  buttonText: string;
}

interface State {
  notifications: Array<Notification> | undefined;
}

const BASE_URL = '/api/notifications/onboarding';

export const useOnboardingStore = defineStore('onboarding', {
  state: (): State => ({
    notifications: undefined
  }),

  getters: {},

  actions: {
    async getNotifications (): Promise<void> {
      try {
        const { data } = await this.$axios.get<Array<NotificationRaw>>(BASE_URL);
        // eslint-disable-next-line camelcase
        this.notifications = data?.map(({
          id,
          message,
          slug,
          button_text: buttonText
        }: NotificationRaw) => ({
          id,
          message,
          slug,
          buttonText
        }));
      } catch (error) {
        console.error('🚀 ~ file: onboarding.ts ~ getNotifications ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async confirmNotification (id: number): Promise<void> {
      try {
        await this.$axios.patch(`${ BASE_URL }/${ id }`);

        this.notifications = this.notifications?.filter((item) => item.id !== id);
      } catch (error) {
        console.error('🚀 ~ file: onboarding.ts ~ confirmNotification ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    }
  }
});
