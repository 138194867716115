var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in",
      "duration": _vm.animationDuration
    }
  }, [_vm.isOpen ? _c('div', {
    class: _vm.$style.baseModal,
    on: {
      "click": _vm.closeOnClickBackdrop
    }
  }, [_c('dialog', {
    ref: "dialog",
    class: _vm.$style.dialog,
    on: {
      "close": _vm.close
    }
  }, [_c('div', {
    class: _vm.$style.wrapper
  }, [_c('div', {
    ref: "inner",
    class: _vm.$style.inner
  }, [_vm._t("header", null, {
    "close": _vm.close
  }), _vm._v(" "), _vm._t("default", function () {
    return [_c('div', {
      class: _vm.$style.text
    }, [_vm._t("text", function () {
      return [_vm._v("\n                " + _vm._s(_vm.text) + "\n              ")];
    }, {
      "text": _vm.text
    })], 2), _vm._v(" "), _vm.$scopedSlots.buttons ? _c('div', {
      class: _vm.$style.buttons
    }, [_vm._t("buttons", null, {
      "close": _vm.close
    })], 2) : _vm._e()];
  }, {
    "close": _vm.close
  }), _vm._v(" "), _vm._t("footer", null, {
    "close": _vm.close
  })], 2)])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };