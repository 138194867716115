import getDeviceFromRequest from '~platfrom/device.js'
import Breakpoint from '~platfrom/breakpoint.js'
import Application from '~platfrom/application.js'
import Vue from 'vue';
export default ({req,app}, inject) => {
    const  options = {"mobileBreakpoint":768,"scrollBarWidth":17,"thresholds":{"xs":412,"sm":768,"md":1024,"lg":1440},"defaultUserAgent":"Mozilla\u002F5.0 (Linux; Android 5.1.1; Nexus 6 Build\u002FLYZ28E) AppleWebKit\u002F537.36 (KHTML, like Gecko) Chrome\u002F64.0.3282.39 Mobile Safari\u002F537.36"};

    const device = getDeviceFromRequest(req,options)
    const breakpoint = new Breakpoint(options, device);
    const ovservedBreakpoint = Vue.observable(breakpoint)
    app.$device = device;
    inject('device', device);

    app.$breakpoint = ovservedBreakpoint;
    inject('breakpoint', ovservedBreakpoint);

    const application = new Application();

    app.$application = application;
    inject('application', application);
};
