import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.$style.HeaderUserDropdown, _defineProperty({}, _vm.$style._opened, _vm.isOpen)]
  }, [_c('div', {
    staticClass: "container",
    class: _vm.$style.header,
    on: {
      "click": _vm.handleToggle
    }
  }, [_c('div', {
    class: _vm.$style.wrapper
  }, [_c('div', {
    staticClass: "menu-select__title",
    class: _vm.$style.label
  }, [_c('SIcon', {
    class: _vm.$style.icon,
    attrs: {
      "icon": "user",
      "size": "24"
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.user
  }, [_c('span', {
    class: _vm.$style.name
  }, [_vm._v("\n            " + _vm._s(_vm.userData.name) + "\n          ")]), _vm._v(" "), _c('span', {
    class: _vm.$style.role
  }, [_vm._v("\n            " + _vm._s(_vm.userData.role) + "\n          ")])])], 1), _vm._v(" "), _c('span', {
    class: _vm.$style.arrow
  }, [_c('SIcon', {
    class: _vm.$style.arrowIcon,
    attrs: {
      "size": "16",
      "icon": "chevron-down"
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    ref: "container",
    staticClass: "container",
    class: _vm.$style.container,
    style: _vm.style
  }, [_c('div', {
    class: _vm.$style.link,
    on: {
      "click": _vm.logout
    }
  }, [_c('SIcon', {
    class: _vm.$style.linkIcon,
    attrs: {
      "icon": "i-logout",
      "size": "24"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.linkTitle
  }, [_vm._v("\n        Выйти\n      ")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };