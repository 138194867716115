// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NTitle_9LvlU{color:#012345;font-family:StranaFont,sans-serif;font-style:normal;font-weight:400}.h1_HG4xD{font-size:4rem;letter-spacing:-.1rem;line-height:1.2}@media only screen and (max-width:1439.98px){.h1_HG4xD{font-size:2.8rem;line-height:1.3}}@media only screen and (max-width:767.98px){.h1_HG4xD{font-size:2.4rem;line-height:1.4;margin-bottom:1.8rem}}.h2_aPB\\+e{font-size:2.8rem;line-height:1.2}@media only screen and (max-width:1439.98px){.h2_aPB\\+e{font-size:2rem}}@media only screen and (max-width:767.98px){.h2_aPB\\+e{font-size:1.8rem}}.h3_ETwjC{font-size:2.4rem;line-height:1.32}@media only screen and (max-width:767.98px){.h3_ETwjC{font-size:2rem;line-height:1.56}}.h4_W5eQR{font-size:2rem;line-height:140%}@media only screen and (max-width:1439.98px){.h4_W5eQR{font-size:1.8rem;line-height:135%}}.h5_jRgEJ{font-size:1.8rem;line-height:140%}@media only screen and (max-width:1439.98px){.h5_jRgEJ{font-size:1.6rem}}.h6_u3k9d{font-size:1.6rem;line-height:140%}@media only screen and (max-width:1439.98px){.h6_u3k9d{font-size:1.4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NTitle": "NTitle_9LvlU",
	"h1": "h1_HG4xD",
	"h2": "h2_aPB+e",
	"h3": "h3_ETwjC",
	"h4": "h4_W5eQR",
	"h5": "h5_jRgEJ",
	"h6": "h6_u3k9d"
};
module.exports = ___CSS_LOADER_EXPORT___;
