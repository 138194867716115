var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_vm.href ? 'NuxtLink' : 'button', {
    tag: "component",
    class: _vm.$style.HeaderButton,
    attrs: {
      "to": _vm.href
    }
  }, [_vm._t("default", function () {
    return [_c(_setup.SIcon, {
      attrs: {
        "icon": _setup.props.icon,
        "size": "24"
      }
    }), _vm._v("\n    " + _vm._s(_setup.props.text) + "\n  ")];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };