const promoApi = {
  promo: {
    tags: '/api/promotions/promotions',
    all: '/api/promotions',
    getPromoItem: (slug: string) => `/api/promotions/${ slug }`,
    getMarkBenefit: (slug: string) => `/api/promotions/${ slug }/mark_benefit`
  }
};
export default promoApi;

export const promoApiConfig = promoApi.promo;
