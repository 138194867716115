import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/web.timers.js";
import { pageScrollService } from '@@/shared/services/PageScrollService';
export default {
  name: 'BaseModal',
  props: {
    text: {
      type: String,
      default: undefined
    },
    open: {
      type: Boolean,
      default: false
    },
    backdropClose: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      animationDuration: 300,
      timeoutId: null
    };
  },
  computed: {
    isOpen: {
      get: function get() {
        return this.open;
      },
      set: function set(value) {
        this.$emit('update:open', value);
      }
    }
  },
  watch: {
    isOpen: {
      handler: function handler(value) {
        var _this = this;
        if (process.client) {
          this.$nextTick(function () {
            var dialog = _this.$refs.dialog;
            if (value) {
              var _dialog$showModal;
              pageScrollService.lock();
              dialog === null || dialog === void 0 || (_dialog$showModal = dialog.showModal) === null || _dialog$showModal === void 0 || _dialog$showModal.call(dialog);
            } else {
              var _dialog$close;
              dialog === null || dialog === void 0 || (_dialog$close = dialog.close) === null || _dialog$close === void 0 || _dialog$close.call(dialog);
              _this.timeoutId = setTimeout(function () {
                return pageScrollService.unlock();
              }, _this.animationDuration);
            }
          });
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    pageScrollService.unlock();
    clearTimeout(this.timeoutId);
  },
  methods: {
    close: function close() {
      pageScrollService.unlock();
      this.$emit('close');
    },
    closeOnClickBackdrop: function closeOnClickBackdrop(e) {
      if (this.backdropClose && e.target !== this.$refs.inner && !this.$refs.inner.contains(e.target)) {
        this.isOpen = false;
      }
    }
  }
};