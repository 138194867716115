// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderWithoutLogin_TQedG{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-flex:1;-ms-flex:1;flex:1;max-width:100%;padding-left:5.6rem;width:100%}@media only screen and (max-width:1439.98px){.HeaderWithoutLogin_TQedG{padding-left:4rem}}@media only screen and (max-width:767.98px){.HeaderWithoutLogin_TQedG{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;padding-left:0}}.link_l5ZMP{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}@media only screen and (max-width:767.98px){.link_l5ZMP{display:none}}.linkText_7I\\+Lc{color:#012345;font-size:14px;font-weight:500;line-height:1.16;margin-left:20px}@media only screen and (max-width:1439.98px){.linkText_7I\\+Lc{display:none}}@media only screen and (max-width:767.98px){.linkText_7I\\+Lc{display:none}}.mobileContent_JMK\\+J{display:none}.mobileContent_JMK\\+J>button:first-child{margin-right:5px}@media only screen and (max-width:767.98px){.mobileContent_JMK\\+J{display:block}}.right_k2A4t{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:80px}@media only screen and (max-width:1023.98px){.right_k2A4t{gap:40px}}.right_k2A4t .button_SZ81O.hoverBase_KgowL{--s-button-font-size:1.4rem;--s-button-font-weight:500;--s-button-background-color-hover:#012345}@media only screen and (max-width:767.98px){.right_k2A4t .button_SZ81O{display:none}}.phoneButton_39b4S{--s-button-custom-size:3.2rem}.hoverableLink_b6grU{margin-left:auto}.userButton_H3V81{margin-left:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderWithoutLogin": "HeaderWithoutLogin_TQedG",
	"link": "link_l5ZMP",
	"linkText": "linkText_7I+Lc",
	"mobileContent": "mobileContent_JMK+J",
	"right": "right_k2A4t",
	"button": "button_SZ81O",
	"hoverBase": "hoverBase_KgowL",
	"phoneButton": "phoneButton_39b4S",
	"hoverableLink": "hoverableLink_b6grU",
	"userButton": "userButton_H3V81"
};
module.exports = ___CSS_LOADER_EXPORT___;
