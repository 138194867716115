export function scrollTo (id: string | null = null, force: boolean = false, offsetHeader: boolean = false): void {
  if (!id) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: force ? 'auto' : 'smooth'
    });
  } else {
    let paddingTop = 0;

    if (offsetHeader) {
      const header = document.querySelector('#scroll-header') as HTMLElement;
      paddingTop = header ? header.clientHeight : 0;
    }

    const target = document.getElementById(id);

    if (target) {
      const position = target.getBoundingClientRect().top + window.pageYOffset - paddingTop;
      window.scroll({
        top: position,
        left: 0,
        behavior: force ? 'auto' : 'smooth'
      });
    }
  }
}
