import { defineStore } from 'pinia';
interface ConsultationResponse {
  text: string;
  slug: string;
}

interface Consultation {
  text: string;
  value: string;
}

interface State {
  consultationTypes: Array<Consultation>;
}

export const useAccountStore = defineStore('account', {
  state: (): State => ({
    consultationTypes: []
  }),

  getters: {},

  actions: {
    async resetPassword (email: string): Promise<void> {
      try {
        const url = this.$api.account.resetPassword;
        await this.$axios.post(url, {
          email
        });
      } catch (error: unknown) {
        console.error('🚀 ~ file: account.ts ~ resetPassword ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async getConsultationTypes (): Promise<void> {
      try {
        const url = this.$api.account.consultationTypes;
        const {
          data
        } = await this.$axios.get<Array<ConsultationResponse>>(url);

        this.consultationTypes = normalizeConsultations(data);
      } catch (error: unknown) {
        console.error('🚀 ~ file: account.js ~ getConsultationTypes ~ e', error);
        this.$sentry.captureException(error);

        throw error;
      }
    }
  }
});

function normalizeConsultations (response: Array<ConsultationResponse>): Array<Consultation> {
  if (!response || !Array.isArray(response)) {
    return [];
  }

  return response
    .filter((item) => item)
    .map(({ text, slug }) => ({
      text: capitalizeFirstLetter(text),
      value: slug
    }));
}

function capitalizeFirstLetter (string: string): string {
  if (!string?.length) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}
