import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.number.constructor.js";
import { SIcon } from '@strana-artw/ui-kit';
import { toRefs } from 'vue';
import { useScreenPlugin } from '@/plugins/vue-screen';
export default {
  name: 'ScoreLabel',
  components: {
    SIcon: SIcon
  },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    theme: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'large'
    },
    iconSizeProp: {
      type: Number,
      default: null
    },
    prefix: {
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var _toRefs = toRefs(useScreenPlugin()),
      lg = _toRefs.lg;
    return {
      lg: lg
    };
  },
  computed: {
    isSmallSize: function isSmallSize() {
      return this.size === 'small' && this.lg || this.size === 'large' && !this.lg || this.theme === 'dark';
    },
    isXSmallSize: function isXSmallSize() {
      return this.size === 'hint' || this.size === 'small' && !this.lg;
    },
    iconSize: function iconSize() {
      if (this.iconSizeProp) {
        return this.iconSizeProp;
      }
      if (this.isSmallSize) {
        return 12;
      }
      if (this.isXSmallSize) {
        return 8;
      }
      return 24;
    }
  }
};