import ResizeObserver from 'resize-observer-polyfill';

export default class Breakpoint {
    constructor(config, device = null) {
        const {
            mobileBreakpoint,
            scrollBarWidth,
            thresholds,
        } = config;

        this.mobileBreakpoint = mobileBreakpoint;
        this.scrollBarWidth = scrollBarWidth;
        this.thresholds = thresholds;

        if (device) {
            const { isTablet, isMobile } = device;
            if (isTablet) {
                this.defaultWidth = thresholds.md;
            } else if (isMobile) {
                this.defaultWidth = thresholds.xs-1;
            } else {
                this.defaultWidth = thresholds.lg;
            }
        } else {
            this.defaultWidth = 0;
        }

        this.init();
    }

    init() {
        this.update();

        /* istanbul ignore if */
        if (typeof window === 'undefined') {
            return;
        }
        const observer = new ResizeObserver(this.update.bind(this));
        observer.observe(document.documentElement);
    }

    /* eslint-disable-next-line max-statements */
    update() {
        const height = this.getClientHeight();
        const { width, scrollBarWidth } = this.getClientWidth();

        this.scrollBarWidth = scrollBarWidth;

        const xs = width < this.thresholds.xs;
        const sm = width < this.thresholds.sm && !xs;
        const md = width <= this.thresholds.md && !(sm || xs);
        const lg = width < this.thresholds.lg - this.scrollBarWidth && !(md || sm || xs);
        const xl = width >= this.thresholds.lg - this.scrollBarWidth;

        this.height = height;
        this.width = width;

        this.xs = xs;
        this.sm = sm;
        this.md = md;
        this.lg = lg;
        this.xl = xl;

        this.xsOnly = xs;
        this.smOnly = sm;
        this.smAndDown = (xs || sm) && !(md || lg || xl);
        this.smAndUp = !xs && (sm || md || lg || xl);
        this.mdOnly = md;
        this.mdAndDown = (xs || sm || md) && !(lg || xl);
        this.mdAndUp = !(xs || sm) && (md || lg || xl);
        this.lgOnly = lg;
        this.lgAndDown = (xs || sm || md || lg) && !xl;
        this.lgAndUp = !(xs || sm || md) && (lg || xl);
        this.xlOnly = xl;

        switch (true) {
            case xs:
                this.name = 'xs';
                break;
            case sm:
                this.name = 'sm';
                break;
            case md:
                this.name = 'md';
                break;
            case lg:
                this.name = 'lg';
                break;
            default:
                this.name = 'xl';
                break;
        }

        if (typeof this.mobileBreakpoint === 'number') {
            this.mobile = width < parseInt(this.mobileBreakpoint, 10);

            return;
        }

        const breakpoints = {
            xs: 0,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
        };

        const current = breakpoints[this.name];
        const max = breakpoints[this.mobileBreakpoint];

        this.mobile = current <= max;
    }

    // Cross-browser support as described in:
    // https://stackoverflow.com/questions/1248081
    getClientWidth() {
        let width = this.defaultWidth || 0;
        let scrollBarWidth = this.scrollBarWidth || 0;
        /* istanbul ignore if */
        if (typeof window !== 'undefined') {
            width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        }
        return { width, scrollBarWidth };
    }

    getClientHeight() {
    /* istanbul ignore if */
        if (typeof document === 'undefined') {
            return 0;
        } // SSR
        return Math.max(document.documentElement?.clientHeight, window.innerHeight || 0);
    }
}
