var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomTemplateWithLeftBlock', {
    attrs: {
      "aside-width": "360px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('TheHeader')];
      },
      proxy: true
    }, {
      key: "overlays",
      fn: function fn() {
        return [_c('TheBurgerMenu'), _vm._v(" "), _c('TheModal')];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };