var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "rootRef",
    class: _vm.$style.HeaderContactsButton
  }, [_c(_setup.HeaderButton, {
    class: _vm.$style.button,
    attrs: {
      "icon": "i-contacts-book-fill",
      "text": "Контакты"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.handleContacts.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _setup.contactsContentOpen ? _c(_setup.ContactsContent, {
    ref: "contactsContent",
    class: _vm.$style.contactsContent
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };