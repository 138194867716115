import { Context } from '@nuxt/types';
import type { Pinia } from 'pinia';

// требуется для корректной работы chessboard
export default ({ $pinia, $portal }: Context): void => {
  $pinia.use(({ store }) => {
    store.apiInstance = $portal.create();
  });
};

declare module 'vue/types/vue' {
  interface Vue {
    $pinia: Pinia;
  }
}
