var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.Error
  }, [_c('h1', {
    class: _vm.$style.title
  }, [_vm._v("\n    " + _vm._s(_vm.errorTitle) + "\n  ")]), _vm._v(" "), _c('p', {
    class: _vm.$style.text
  }, [_vm._v("\n    " + _vm._s(_vm.errorText) + "\n  ")]), _vm._v(" "), _c('SButton', {
    class: _vm.$style.button,
    attrs: {
      "n-link": "",
      "href": "/",
      "size": "x-large",
      "theme": "primary",
      "rounded": ""
    }
  }, [_vm._v("\n    Перейти на главную\n  ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };