var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.MenuDesktopActivator
  }, [_c('div', {
    class: _vm.$style.pseudoButton
  }, [_c(_setup.SIcon, {
    class: _vm.$style.icon,
    attrs: {
      "icon": "i-burger-menu",
      "size": "24"
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };