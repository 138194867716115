// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_BOSQJ{display:-ms-grid;display:grid;height:-webkit-max-content;height:-moz-max-content;height:max-content;min-height:100vh;position:relative;-ms-grid-rows:auto minmax(0,1fr);grid-auto-rows:auto;grid-template-rows:auto minmax(0,1fr);-ms-grid-columns:minmax(0,100vw);grid-template-columns:minmax(0,100vw)}.header_qA6Qi{position:sticky;top:0;z-index:20}.menu_sOvH-{left:0;position:fixed;top:14rem;-webkit-transform:translateX(-100%);transform:translateX(-100%);-webkit-transition:-webkit-transform .1s ease;transition:-webkit-transform .1s ease;transition:transform .1s ease;transition:transform .1s ease,-webkit-transform .1s ease}.menu_sOvH-.visible_K0dB7{-webkit-transform:translateX(0);transform:translateX(0)}@media only screen and (max-width:1023.98px){.menu_sOvH-{bottom:0;top:0;-webkit-transition:-webkit-transform .2s ease;transition:-webkit-transform .2s ease;transition:transform .2s ease;transition:transform .2s ease,-webkit-transform .2s ease}}.menuWrapper_4T3ge{position:absolute;z-index:25}.backdropOverlay_JxwXH{z-index:25}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Layout": "Layout_BOSQJ",
	"header": "header_qA6Qi",
	"menu": "menu_sOvH-",
	"visible": "visible_K0dB7",
	"menuWrapper": "menuWrapper_4T3ge",
	"backdropOverlay": "backdropOverlay_JxwXH"
};
module.exports = ___CSS_LOADER_EXPORT___;
