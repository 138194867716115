var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.TheFooter
  }, [_c('div', {
    class: _vm.$style.wrapperTop
  }, [_c('img', {
    class: _vm.$style.logo,
    attrs: {
      "src": _vm.logo
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.columnWrapper
  }, [_c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n        " + _vm._s("\xA9 2008 - ".concat(_vm.currentYear, ".")) + "\n      ")]), _vm._v(" "), _c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n        «Страна Девелопмент»\n      ")])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.wrapperBottom
  }, [_c('div', {
    class: _vm.$style.columnWrapper
  }, [_c('IconArtw', {
    attrs: {
      "is-white-w": true
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n        поддержка\n      ")])], 1), _vm._v(" "), _c('SButton', {
    attrs: {
      "theme": "grey-slate",
      "equal": "",
      "size": "large"
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('SIcon', {
    class: _vm.$style.arrowDown,
    attrs: {
      "icon": "i-arrowDown",
      "color": "#fcfcfc",
      "size": "24"
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };