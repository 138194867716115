import { defineStore } from 'pinia';
import { ref } from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useContext } from '@nuxtjs/composition-api';
import { accountApiConfig } from '@/config/api/account';

interface RepresesData {
  payload: {
    agency: {
      city: string;
      type: string;
      inn: string;
      name: string;
    };
  };

  'company_files'?: File | null;
  fileCompanyCard?: File | null;
  fileProcuratory?: File | null;
  fileCharter?: File | null;
  fileOgrn?: File | null;
  fileInn?: File | null;
  fileOgrnip?: File | null;
}

interface AgentData {
  'name': string;
  'surname': string;
  'patronymic': string;
  'phone': string;
  'password_1': string;
  'password_2': string;
  'email': string;
  'is_contracted': boolean;
  'agency': number;
  'city_of_sale_id': number;
}

const Errors = {
  TYPICAL: 'Произошла ошибка',
  SERVER: 'Ошибка на сервере'
};

export const useRegistrationStore = defineStore('registration', () => {
  const represesData = ref<Partial<RepresesData>>({});
  const agentData = ref<Partial<AgentData>>({});
  const personalData = ref<Partial<AgentData>>({});

  function setRepresesData (payload: RepresesData): void {
    represesData.value = payload;
  }

  function setAgentData (payload: AgentData): void {
    agentData.value = payload;
  }

  function setPersonalData (payload: AgentData): void {
    personalData.value = payload;
  }

  function resetRegistrationData (): void {
    represesData.value = {};
    agentData.value = {};
    personalData.value = {};
  }

  const { $axios } = useContext();

  async function checkUnique ({
    email,
    phone,
    role
  }: {
      email: string;
      phone: string;
      role: string;
    }): Promise<void> {
    const url = accountApiConfig.checkContacts;
    await $axios.$post(url, {
      email,
      phone,
      role
    });
  }

  async function represRegistration (payload: FormData) {
    const url = accountApiConfig.register('represes');

    const {
      agency
    } = await $axios.$post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (!agency?.id) {
      throw new Error(Errors.SERVER);
    }
  }

  async function agentRegistration (agencyId: string) {
    const url = accountApiConfig.register('agents');
    const {
      id
    } = await $axios.$post(url, {
      agency: agencyId,
      ...personalData.value,
      is_contracted: true
    });

    if (!id) {
      throw new Error(Errors.SERVER);
    }
  }

  return {
    ...decorateApiActions({
      checkUnique,
      represRegistration,
      agentRegistration
    }, 'store/registration', true),
    personalData,
    agentData,
    represesData,
    setRepresesData,
    setAgentData,
    setPersonalData,
    resetRegistrationData
  };
});
