import { defineStore } from 'pinia';
import { PROJECT_STATUSES } from '@@/shared/constants/statuses';

interface SkyColor {
  value: string;
  label: string;
}

interface Status {
  value: string;
  label: string;
}

interface City {
  id: number;
  name: string;
  shortName: string | null;
  color: string;
  slug: string;
}

interface Range {
  min: number;
  max: number;
}

interface Metro {
  name: string;
}

interface Transport {
  id: number;
  name: string;
  iconContent: string;
}

interface ProjectsResponseV1 {
  city: string;
  // eslint-disable-next-line camelcase
  global_id?: string;
  id: number;
  name: string;
  slug: string;
  status: Status;
  hasAdditionalTemplates?: boolean;
}

interface Image {
  src: string;
  s3: string;
  aws: string;
  proxy?: string;
}

export interface ProjectsResponseV3 {
  id: number;
  globalId: string;
  cardImage?: Image;
  cardImageDisplay?: Image;
  cardImageNight?: Image;
  cardImageNightDisplay?: Image;
  cardSkyColor: SkyColor;
  city: City;
  commercialCount: number;
  flatAreaRange: Range;
  flatsCount: number;
  metro: Metro;
  minFlatPrice: number;
  name: string;
  parkingPantryCount: number;
  projectColor: string;
  slug: string;
  status: Status;
  title: string;
  transport: Transport;
  transportTime: number;
  showFavouriteButton: boolean;
  salesStartDate: string;
  hasAdditionalTemplates: boolean | null;
  isReplacePrice: boolean;
}

interface State {
  projects: Array<ProjectsResponseV1>;
  globalProjects: Array<ProjectsResponseV3>;
}

export const useProjectsStore = defineStore('projects', {
  state: () => ({
    projects: [],
    globalProjects: []
  }),
  getters: {
    projectsOptions ({ globalProjects }: State) {
      return globalProjects.map(({
        name,
        id,
        city
      }) => ({
        id,
        text: name,
        value: id,
        citySlug: city.slug
      }));
    },

    projectsWithTemplates (
      { projects }: State
    ): Array<ProjectsResponseV1> {
      return projects.filter((
        { hasAdditionalTemplates }: ProjectsResponseV1
      ) => hasAdditionalTemplates);
    },

    // Пока продаем только кв, поэтому фильтруем проекты у которых есть кв.
    // В будущем, когда буду продажи коммерческой недвижки, надо переделать фильтр
    globalProjectsWithFlats (
      { globalProjects }: State
    ): Array<ProjectsResponseV3> | undefined {
      if (!globalProjects) {
        return;
      }

      return globalProjects.filter(({ flatsCount }) => flatsCount);
    },

    specsGlobalProjects () {
      if (Array.isArray(this.globalProjectsWithFlats)) {
        return this.globalProjectsWithFlats.map((
          {
            name,
            id
          }: ProjectsResponseV3
        ) => ({
          value: id,
          label: name
        }));
      }

      return [];
    },

    getProjectById (
      { globalProjects }: State
    ) {
      return function (
        projectId: number
      ): ProjectsResponseV3 | undefined {
        if (!globalProjects) {
          return;
        }

        return globalProjects.find(({ id }) => id === Number(projectId));
      };
    },

    getProjectByGlobalId (
      { globalProjects }: State
    ) {
      return function (
        projectGlobalId: string
      ): ProjectsResponseV3 | undefined {
        if (!globalProjects) {
          return;
        }

        return globalProjects.find(({ globalId }) => globalId === projectGlobalId);
      };
    },

    getProjectBySlug (
      { globalProjects }: State
    ) {
      return function (
        projectSlug: string
      ): ProjectsResponseV3 | undefined {
        if (!globalProjects) {
          return;
        }

        return globalProjects.find(({ slug }) => slug === projectSlug);
      };
    }
  },

  actions: {
    async getGlobalProjects (force = false): Promise<void> {
      if ((this.globalProjects.length) && !force) {
        return;
      }
      try {
        const url = this.$api.projects.listV3;
        this.globalProjects = await this.$axios.$get<Array<ProjectsResponseV3>>(url, {
          params: {
            status: [PROJECT_STATUSES.CURRENT, PROJECT_STATUSES.FUTURE]
          }
        });
      } catch (error: unknown) {
        console.log('🚀 ~ file: projects.ts ~ getGlobalProjects ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    }
  }
});
