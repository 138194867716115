// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CertifiedPartner_nUc9t{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;background:-webkit-gradient(linear,left top,right top,from(#92278f),color-stop(57.99%,#c6168d),to(#ed1c24));background:linear-gradient(90deg,#92278f,#c6168d 57.99%,#ed1c24);border:.1rem solid #fff;border-radius:9.6rem;height:4.8rem;justify-content:center;min-width:21rem}@media only screen and (max-width:1023.98px){.CertifiedPartner_nUc9t{height:3.5rem;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}}.wrapper_lfOxp{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;gap:.8rem;justify-content:center}@media only screen and (max-width:1023.98px){.wrapper_lfOxp{padding:.8rem}}.textWrapper_HM\\+WJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}@media only screen and (max-width:1023.98px){.textWrapper_HM\\+WJ{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}}.textWrapper_HM\\+WJ span{color:#fff;font-family:StranaFont,sans-serif;font-size:1.2rem;font-style:normal;font-weight:500;letter-spacing:-.03rem;line-height:120%;text-transform:uppercase}@media only screen and (max-width:1023.98px){.textWrapper_HM\\+WJ span{font-size:1rem;letter-spacing:-.013rem}}@media only screen and (max-width:767.98px){.textWrapper_HM\\+WJ span{font-size:8px}}.textWrapper_HM\\+WJ span:first-child{font-size:.9rem;letter-spacing:-.02rem}@media only screen and (max-width:1023.98px){.textWrapper_HM\\+WJ span:first-child{font-size:1rem;letter-spacing:-.013rem}}@media only screen and (max-width:767.98px){.textWrapper_HM\\+WJ span:first-child{font-size:8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CertifiedPartner": "CertifiedPartner_nUc9t",
	"wrapper": "wrapper_lfOxp",
	"textWrapper": "textWrapper_HM+WJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
