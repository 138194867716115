var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.$style.scoreLabel, _vm.$style[_vm.theme], _vm.$style[_vm.size]]
  }, [_c('span', {
    class: _vm.$style.scoreValue
  }, [_vm._v("\n    " + _vm._s(_vm.prefix) + _vm._s(_vm.value) + "\n  ")]), _vm._v(" "), _c('SIcon', {
    attrs: {
      "icon": "i-crown",
      "size": _vm.iconSize
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };