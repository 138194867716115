// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactsContent_-ltuj{background-color:#012345;border-radius:.8rem}.managerInfo_8z0f7{border-radius:.8rem;padding:var(--manager-padding)}.managerInfo_8z0f7:not(:last-child){border-bottom:.1rem solid #344f6a}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContactsContent": "ContactsContent_-ltuj",
	"managerInfo": "managerInfo_8z0f7"
};
module.exports = ___CSS_LOADER_EXPORT___;
