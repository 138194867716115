// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderHelpButton_pm5\\+i{position:relative}.helpContent_RBXuU{position:absolute;right:0;top:calc(100% + 1rem);width:29rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderHelpButton": "HeaderHelpButton_pm5+i",
	"helpContent": "helpContent_RBXuU"
};
module.exports = ___CSS_LOADER_EXPORT___;
