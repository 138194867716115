// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderButton_vnfez{display:-webkit-box;display:-ms-flexbox;display:flex;gap:1.2rem;padding:1.2rem 2.4rem 1.2rem 2.8rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;background-color:#fff;border-radius:100rem;color:#677b8f;font-size:1rem;font-weight:500;justify-content:center;letter-spacing:.1em;line-height:146%;text-transform:uppercase}.HeaderButton_vnfez:hover{background-color:#e4e8eb}.HeaderButton_vnfez:focus-visible{outline:2px solid #4169e1;outline-offset:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderButton": "HeaderButton_vnfez"
};
module.exports = ___CSS_LOADER_EXPORT___;
