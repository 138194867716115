import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "rootRef",
    class: [_vm.$style.HeaderProfileButton, _defineProperty({}, _vm.$style.menuOpen, _setup.menuOpen)]
  }, [_c(_setup.HeaderButton, {
    class: _vm.$style.userMenuButton,
    attrs: {
      "aria-label": "Открыть профиль"
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.userMenuButtonClick.apply(null, arguments);
      }
    }
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "i-user-fill",
      "size": "24"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.verticalFlex
  }, [_c('span', {
    class: _vm.$style.agentName
  }, [_vm._v(_vm._s(_setup.userInfo.name))]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_setup.userInfo.role))])]), _vm._v(" "), _c(_setup.SIcon, {
    class: _vm.$style.chevron,
    attrs: {
      "icon": "i-chevron-down-line",
      "size": "24"
    }
  })], 1), _vm._v(" "), _c(_setup.HeaderProfileDropdown, {
    ref: "menu",
    class: _vm.$style.dropdownMenu,
    style: "inset-inline-".concat(_vm.dropdownAlign, ": 0;"),
    on: {
      "dropdown-item-click": function dropdownItemClick($event) {
        _setup.menuOpen = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };