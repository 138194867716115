export function getBodyScrollbarWidth (): number {
  if (!process.client) {
    return 0;
  }

  const documentWidth = document.documentElement.clientWidth;
  const windowsWidth = window.innerWidth;

  return windowsWidth - documentWidth;
}
