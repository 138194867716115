import {
  ILogger,
  LogLevels
} from '../interface';
import { BaseLogger } from './baseLogger';

export class ConsoleLogger extends BaseLogger implements ILogger {
  log (level: LogLevels, data: Error | string | unknown): void {
    if (!this.isEnabled(level)) {
      return;
    }

    const dataAsArray = Array.isArray(data) ? data : [data];
    switch (level) {
      case 'error': {
        console.error(...dataAsArray);

        return;
      }
      case 'warning': {
        console.warn(...dataAsArray);

        return;
      }
      case 'log': {
        console.log(...dataAsArray);
      }
    }

    console.error(`level ${ level } logging is not implemented`);
  }
}
