// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderButtonMenu_I0lws[class*=HeaderButtonMenu]{background-color:#012345;border-radius:1rem;color:#fff;padding:1rem}.HeaderButtonMenu_I0lws[class*=HeaderButtonMenu]:hover{background-color:#344f6a}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderButtonMenu": "HeaderButtonMenu_I0lws"
};
module.exports = ___CSS_LOADER_EXPORT___;
