var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": _vm.data.transition,
      "mode": "out-in",
      "duration": _vm.data.duration
    },
    on: {
      "enter": _vm.enter,
      "leave": _vm.leave,
      "after-leave": _vm.afterLeave
    }
  }, [_vm.data.component ? _c('div', {
    class: _vm.classes,
    attrs: {
      "data-ios-should-lock-scroll": ""
    }
  }, [_c('div', {
    class: _vm.$style.wrapper,
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm.data.floatingButton ? _c('SButton', {
    class: _vm.$style.closeButton,
    attrs: {
      "id": "drawer-close",
      "equal": "",
      "theme": "ghost"
    },
    on: {
      "click": _vm.handleCloseStart
    }
  }, [_c('SIcon', {
    attrs: {
      "icon": "i-close",
      "size": "24"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c(_vm.data.component, _vm._b({
    tag: "component",
    on: {
      "close": _vm.close
    }
  }, 'component', _vm.data.attrs, false))], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.overlay
  })]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };