import { defineStore } from 'pinia';

import type {
  StateChanger
} from 'vue-infinite-loading';
import type {
  OrderingItem,
  Facets,
  Lookup,
  FacetsResponse,
  SpecsResponse,
  ClientsResponse
} from '@/types/Clients';
import type {
  PageInfo
} from '@/types/PageInfo';

const defaultOrderingList: Array<OrderingItem> = [{
  label: 'по умолчанию',
  value: 'default'
}];

interface State {
  isCheckedClient: boolean;

  clients: Array<object>;
  pageInfo: PageInfo | object;
  count: number;

  facets: Facets;

  specs: object;

  ordering: Array<OrderingItem>;

  lookup: Lookup;
}

export const useClientsStore = defineStore('clients', {
  state: (): State => ({
    isCheckedClient: false,

    clients: [],
    pageInfo: {},
    count: 0,

    facets: {
      work_period: {},
      status: [],
      project: [],
      property_type: []
    },

    specs: {},

    ordering: [
      ...defaultOrderingList
    ],

    lookup: {
      type: '',
      result: []
    }
  }),

  actions: {
    setIsCheckedClient (payload: boolean): void {
      this.isCheckedClient = payload;
    },

    async getFacets (filters: object): Promise<void> {
      try {
        const {
          role
        } = this.$router.app.$store.getters ?? {};
        const {
          facets: facetsUrl
        } = this.$api.account.clients;
        const url = facetsUrl(role);

        const {
          data: {
            facets
          }
        } = await this.$axios.get<FacetsResponse>(url, {
          params: filters
        });

        this.facets = facets;
      } catch (error: unknown) {
        console.log('🚀 ~ file: clients.ts ~ getFacets ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getSpecs (): Promise<void> {
      try {
        const {
          role
        } = this.$router.app.$store.getters ?? {};
        const {
          specs: specsUrl
        } = this.$api.account.clients;
        const url = specsUrl(role);
        const {
          data: {
            ordering,
            specs
          }
        } = await this.$axios.get<SpecsResponse>(url);

        this.specs = specs;
        this.ordering = [
          ...defaultOrderingList,
          ...ordering
        ];
      } catch (error: unknown) {
        console.error('🚀 ~ file: clients.ts ~ getSpecs ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async fetchLookup (params: object): Promise<void> {
      try {
        const {
          role
        } = this.$router.app.$store.getters ?? {};
        const {
          lookup: lookupUrl
        } = this.$api.account.clients;
        const url = lookupUrl(role);

        const {
          data
        } = await this.$axios.get<Lookup>(url, {
          params
        });

        this.lookup = data;
      } catch (error: unknown) {
        console.error('🚀 ~ file: clients.ts ~ fetchLookup ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async getClients (filters: object): Promise<void> {
      try {
        const {
          role
        } = this.$router.app.$store.getters ?? {};
        const {
          clients
        } = this.$api.account.dependentRole;
        const url = clients('users', role);
        const {
          data: {
            result,
            count,
            page_info: pageInfo
          }
        } = await this.$axios.get<ClientsResponse>(url, {
          params: filters
        });

        this.clients = result;
        this.pageInfo = pageInfo;
        this.count = count;
      } catch (error: unknown) {
        console.log('🚀 ~ file: clients.ts ~ getClients ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState
      }: {
        page: PageInfo;
        infiniteState: StateChanger;
      }): Promise<void> {
      try {
        if (!page?.next_page) {
          return;
        }

        const {
          data: {
            result,
            page_info: pageInfo
          }
        } = await this.$axios.get<ClientsResponse>(page.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.clients = [
            ...this.clients,
            ...result
          ];
          this.pageInfo = pageInfo;

          if (!pageInfo.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error: unknown) {
        console.log('🚀 ~ file: clients.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    }
  }
});
