import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('VApp', {
    class: _vm.$style.DefaultLayout
  }, [_c('TheHeader', {
    on: {
      "toggle-menu": _vm.toggleHeader
    }
  }), _vm._v(" "), _c('main', {
    class: [_vm.$style.pageWrapper, _defineProperty({}, _vm.$style.fullHeight, _vm.isFixed)],
    style: {
      paddingTop: _vm.height
    }
  }, [_vm._t("default")], 2), _vm._v(" "), _c('client-only', [_vm.isFooter && !_vm.isFixed ? _c('TheFooter') : _vm._e()], 1), _vm._v(" "), _vm.isOpened ? _c('TheBurgerMenu', {
    on: {
      "closed": _vm.toggleHeader
    }
  }) : _vm._e(), _vm._v(" "), _c('TheModal'), _vm._v(" "), _c('TheModalIframe'), _vm._v(" "), _c('TheContextMenu'), _vm._v(" "), _c('client-only', [_c('PortalTarget', {
    attrs: {
      "name": "layout",
      "multiple": ""
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };