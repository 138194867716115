import Vue from 'vue';

export function convertToUnit (str, unit = 'px') {
  if (str === undefined || str === null || str === '') {
    return undefined;
  } else if (isNaN(Number(str))) {
    return String(str);
  } else {
    return `${ Number(str) }${ unit }`;
  }
}

export function createSimpleFunctional (
  c,
  el = 'div',
  name
) {
  return Vue.extend({
    name: name || c.replace(/__/g, '-'),

    functional: true,

    render (h, { data, children }) {
      data.staticClass = `${ c } ${ data.staticClass || '' }`.trim();

      return h(el, data, children);
    }
  });
}


export function deepEqual (a, b) {
  if (a === b) {
    return true;
  }

  if (
    a instanceof Date &&
      b instanceof Date &&
      a.getTime() !== b.getTime()
  ) {
    // If the values are Date, compare them as timestamps
    return false;
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false;
  }

  const props = Object.keys(a);

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false;
  }

  return props.every((p) => deepEqual(a[p], b[p]));
}

// KeyboardEvent.keyCode aliases
export const keyCodes = Object.freeze({
  enter: 13,
  tab: 9,
  delete: 46,
  esc: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  end: 35,
  home: 36,
  del: 46,
  backspace: 8,
  insert: 45,
  pageup: 33,
  pagedown: 34,
  shift: 16
});

export function throttle (fn, limit) {
  let throttling = false;

  return (...args) => {
    if (!throttling) {
      throttling = true;
      setTimeout(() => {
        throttling = false;
      }, limit);

      return fn(...args);
    }
  };
}
export function upperFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function filterObjectOnKeys (obj, keys) {
  const filtered = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (typeof obj[key] !== 'undefined') {
      filtered[key] = obj[key];
    }
  }

  return filtered;
}
const camelizeRE = /-(\w)/g;
export const camelize = (str) => str.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : '');
export function kebabCase (str) {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
export function getSlot (vm, name = 'default', data, optional = false) {
  if (vm.$scopedSlots[name]) {
    return vm.$scopedSlots[name] && vm.$scopedSlots[name](data instanceof Function ? data() : data);
  } else if (vm.$slots[name] && (!data || optional)) {
    return vm.$slots[name];
  }

  return undefined;
}

export function wrapInArray (v = null) {
  return v !== null ? Array.isArray(v) ? v : [v] : [];
}

/* eslint-disable no-eq-null */
export function getObjectValueByPath (obj, path, fallback) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') {
    return fallback;
  }

  if (obj[path] !== undefined) {
    return obj[path];
  }
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot

  return getNestedValue(obj, path.split('.'), fallback);
}


export function getNestedValue (obj, path, fallback) {
  const last = path.length - 1;

  if (last < 0) {
    return obj === undefined ? fallback : obj;
  }

  for (let i = 0; i < last; i++) {
    if (obj === null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj === null || obj === undefined) {
    return fallback;
  }

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function keys (o) {
  return Object.keys(o);
}

export function isUnset (val) {
  return typeof val === 'undefined' || val === null;
}

export function isSet (val) {
  return !isUnset(val);
}
/* eslint-disable no-console,getter-return,no-var */

export function addOnceEventListener (
  el,
  eventName,
  cb,
  options = false
) {
  var once = (event) => {
    cb(event);
    el.removeEventListener(eventName, once, options);
  };

  el.addEventListener(eventName, once, options);
}

const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;'
};

export function escapeHTML (str) {
  return str.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
}

export function getZIndex (el) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) {
    return 0;
  }

  const index = Number(window.getComputedStyle(el).getPropertyValue('z-index'));

  if (!index) {
    return getZIndex(el.parentNode);
  }

  return index;
}

export function padEnd (str, length, char = '0') {
  return str + char.repeat(Math.max(0, length - str.length));
}

export function chunk (str, size = 1) {
  const chunked = [];
  let index = 0;
  while (index < str.length) {
    chunked.push(str.substr(index, size));
    index += size;
  }

  return chunked;
}

export function isObjectEmpty (obj) {
  if (isUnset(obj) || typeof obj !== 'object') {
    return true;
  }

  return Object.keys(obj).length === 0;
}

export function remapInternalIcon (vm, iconName) {
  const component = 'svg-icon';
  let iconNameNormalized = iconName;

  if (iconName.startsWith('$')) {
    iconNameNormalized = kebabCase(iconName.split('$').pop());
  }

  return {
    component,
    key: iconNameNormalized,
    props: {
      name: iconNameNormalized
    }
  };
}

export const humanReadableFileSize = (bytes, binary = false) => {
  const base = binary ? 1024 : 1000;
  const units = ['B', 'K', 'M', 'G', 'T', 'P'];

  let unitIndex = 0;

  if (bytes < base) {
    return `${ bytes } ${ units[unitIndex] }`;
  }

  while (base <= bytes && unitIndex < units.length - 1) {
    bytes /= base;
    ++unitIndex;
  }

  bytes = bytes.toFixed(1);

  const postfix = `${ binary ? 'i' : '' }B`;
  const size = `${ units[unitIndex] }${ postfix }`;

  return `${ bytes } ${ size }`;
};

/**
 * Returns:
 *  - 'normal' for old style slots - `<template slot="default">`
 *  - 'scoped' for old style scoped slots (`<template slot="default" slot-scope="data">`) or bound v-slot (`#default="data"`)
 *  - 'v-slot' for unbound v-slot (`#default`) - only if the third param is true, otherwise counts as scoped
 */
export function getSlotType (vm, name, split) {
  if (vm.$slots[name] && vm.$scopedSlots[name] && vm.$scopedSlots[name].name) {
    return split ? 'v-slot' : 'scoped';
  }

  if (vm.$slots[name]) {
    return 'normal';
  }

  if (vm.$scopedSlots[name]) {
    return 'scoped';
  }
}

export function getPropertyFromItem (
  item,
  property,
  fallback
) {
  if (property === null) {
    return item === undefined ? fallback : item;
  }

  if (item !== Object(item)) {
    return fallback === undefined ? item : fallback;
  }

  if (typeof property === 'string') {
    return getObjectValueByPath(item, property, fallback);
  }

  if (Array.isArray(property)) {
    return getNestedValue(item, property, fallback);
  }

  if (typeof property !== 'function') {
    return fallback;
  }

  const value = property(item, fallback);

  return typeof value === 'undefined' ? fallback : value;
}

export function createRange (length) {
  return Array.from({ length }, (v, k) => k);
}
