import _readOnlyError from "@babel/runtime/helpers/esm/readOnlyError";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.function.name.js";
import { userNameWithInitialsFormatted } from '@@/common/utils/text-utils';
import { SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'HeaderUserInfo',
  components: {
    SIcon: SIcon
  },
  computed: {
    user: function user() {
      var _this$$store$state$au = this.$store.state.auth,
        user = _this$$store$state$au.user,
        strategy = _this$$store$state$au.strategy;
      var roleLabels = {
        agents: 'Агент',
        represes: 'Агентство'
      };
      var _user = user,
        name = _user.name,
        surname = _user.surname,
        patronymic = _user.patronymic;
      return {
        role: roleLabels[strategy],
        name: userNameWithInitialsFormatted(surname, name, patronymic)
      };
    }
  }
};