var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.NFooter
  }, [_c('div', {
    class: _vm.$style.wrapper
  }, [_c('img', {
    class: _vm.$style.logo,
    attrs: {
      "src": "/images/logoGray.svg",
      "alt": "Страна девелопмент"
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.columnWrapper
  }, [_c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n        " + _vm._s("\xA9 2008 - ".concat(_setup.currentYear)) + "\n      ")]), _vm._v(" "), _c('span', {
    class: _vm.$style.text
  }, [_vm._v("\n        Страна Девелопмент\n      ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };