var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.NotificationsContainer
  }, _vm._l(_setup.notificationsData, function (notification) {
    return _c(notification.component, _vm._g(_vm._b({
      key: notification.key,
      tag: "component",
      class: _vm.$style.notificationItem
    }, 'component', notification.props, false), notification.listeners));
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };