import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.HeaderUserBtn,
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "id": "".concat(_vm.wrapperId)
    }
  }, [_c('SButton', {
    class: [_vm.$style.btn, _vm.$style.accountBtn],
    attrs: {
      "id": "".concat(_vm.btnId),
      "theme": "ghost",
      "size": "x-large",
      "icon": _vm.$breakpoint.mobile
    }
  }, [_c('HeaderUserInfo')], 1), _vm._v(" "), _c('VMenu', {
    attrs: {
      "activator": "#".concat(_vm.btnId),
      "attach": "#".concat(_vm.wrapperId),
      "open-on-click": "",
      "content-class": "account-menu"
    }
  }, [_c('VList', {
    class: _vm.$style.accountList
  }, _vm._l(_vm.accountItems, function (item, index) {
    return _c('VListItem', {
      key: index,
      attrs: {
        "to": item.to,
        "dense": ""
      },
      on: {
        "click": item.on
      }
    }, [_c('VListItemIcon', [item.customIcon ? _c('img', {
      class: _vm.$style.customIcon,
      attrs: {
        "src": item.customIcon,
        "alt": ""
      }
    }) : _c('SIcon', {
      attrs: {
        "icon": "i-".concat(item.icon),
        "size": "24"
      }
    })], 1), _vm._v(" "), _c('VListItemTitle', [_c('div', {
      class: _vm.$style.itemTitle
    }, [_vm._v("\n            " + _vm._s(item.title) + "\n            "), item.component ? _c(item.component.name, _vm._b({
      tag: "component"
    }, 'component', item.component.options, false)) : _vm._e()], 1)])], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };