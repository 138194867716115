var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.componentObj.tag, _vm._b({
    tag: "component",
    staticClass: "headerLink",
    class: Boolean(_vm.$slots.icon) && 'withIcon',
    style: _vm.styles
  }, 'component', _vm.componentObj.props, false), [_vm.$slots.text || _vm.text ? _c('span', {
    staticClass: "headerLink__currentValue"
  }, [_vm._t("text", function () {
    return [_vm._v(_vm._s(_vm.text))];
  })], 2) : _vm._e(), _vm._v(" "), _vm.$slots.text || _vm.text ? _c('span', {
    staticClass: "headerLink__hoverValue"
  }, [_vm._t("text", function () {
    return [_vm._v(_vm._s(_vm.text))];
  })], 2) : _vm._e(), _vm._v(" "), _vm.$slots.icon ? _c('span', {
    staticClass: "headerLink__icon"
  }, [_vm._t("icon")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };