import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { mapActions, mapGetters, mapState } from 'vuex';
import TheFooter from '@/components/layout/TheFooter.vue';
export default {
  name: 'LayoutLanding',
  components: {
    TheFooter: TheFooter,
    TheBurgerMenu: function TheBurgerMenu() {
      return import('@/components/layout/TheBurgerMenu');
    }
  },
  data: function data() {
    return {
      height: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('notification', ['notifications'])), mapGetters({
    isOpened: 'header/getHeaderState',
    currentCity: 'getActiveCity'
  })), {}, {
    isFixed: function isFixed() {
      var _this$$route, _this$$route2;
      return ((_this$$route = this.$route) === null || _this$$route === void 0 || (_this$$route = _this$$route.name) === null || _this$$route === void 0 ? void 0 : _this$$route.includes('contract')) || ((_this$$route2 = this.$route) === null || _this$$route2 === void 0 || (_this$$route2 = _this$$route2.name) === null || _this$$route2 === void 0 ? void 0 : _this$$route2.includes('account'));
    }
  }),
  watch: {
    'notifications.length': function notificationsLength() {
      this.getHeaderHeight();
    }
  },
  mounted: function mounted() {
    this.getHeaderHeight();
  },
  methods: _objectSpread(_objectSpread({}, mapActions({
    toggleHeader: 'header/toggleHeader'
  })), {}, {
    getHeaderHeight: function getHeaderHeight() {
      var _this = this;
      this.$nextTick(function () {
        _this.height = "".concat(document.querySelector('header').getBoundingClientRect().height, "px");
      });
    }
  })
};