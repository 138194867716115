import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { throttle } from 'lodash';
import { countLastVisibleItemIndex } from '@strana-artw/cabinets-common';
export default {
  name: 'HeaderNavigation',
  props: {
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    menuPosition: {
      type: String,
      default: 'right',
      validate: function validate(value) {
        return value.includes(['right', 'left']);
      }
    }
  },
  data: function data() {
    return {
      // Список ширин ссылок
      linksWidths: [],
      // Флаг открытия дропдауна
      isDropdown: false,
      // Индекс разделения на 2 списка
      lastVisibleItemIndex: undefined,
      resizeHandler: null
    };
  },
  computed: {
    // Возвращает элементы для списка для строки
    inlineLinks: function inlineLinks() {
      if (this.lastVisibleItemIndex === undefined) {
        return this.items;
      }
      return this.items.slice(0, this.lastVisibleItemIndex);
    },
    // Возвращает элементы для списка для колонки
    dropdownLinks: function dropdownLinks() {
      if (this.lastVisibleItemIndex === undefined) {
        return this.items;
      }
      return this.items.slice(this.lastVisibleItemIndex, this.items.length);
    },
    displayClasses: function displayClasses() {
      return _defineProperty({}, this.$style['m-show'], this.lastVisibleItemIndex);
    },
    dropdownClasses: function dropdownClasses() {
      return _defineProperty({}, this.$style["m-".concat(this.menuPosition)], true);
    },
    isButtonShown: function isButtonShown() {
      return this.lastVisibleItemIndex !== this.items.length;
    }
  },
  mounted: function mounted() {
    this.getLinksWidth();
    this.onResize();
    this.resizeHandler = throttle(this.onResize, 200);
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    countLastVisibleItemIndex: countLastVisibleItemIndex,
    onResize: function onResize() {
      var _this$$refs$headerNa, _this$$refs$headerNa2, _this$$refs$dropdown;
      if (!((_this$$refs$headerNa = this.$refs['header-navigation-inline']) !== null && _this$$refs$headerNa !== void 0 && _this$$refs$headerNa.getBoundingClientRect().width)) {
        return;
      }
      var options = {
        componentWidth: Math.ceil((_this$$refs$headerNa2 = this.$refs['header-navigation-inline']) === null || _this$$refs$headerNa2 === void 0 ? void 0 : _this$$refs$headerNa2.getBoundingClientRect().width),
        itemsLength: this.items.length,
        linksWidths: this.linksWidths,
        dropdownButtonWidth: Math.ceil((_this$$refs$dropdown = this.$refs['dropdown-button']) === null || _this$$refs$dropdown === void 0 ? void 0 : _this$$refs$dropdown.getBoundingClientRect().width)
      };
      this.lastVisibleItemIndex = this.countLastVisibleItemIndex(options);
    },
    // Получение массива ширин элементов
    getLinksWidth: function getLinksWidth() {
      var _this$$refs$menu;
      this.linksWidths = [];
      if ((_this$$refs$menu = this.$refs.menu) !== null && _this$$refs$menu !== void 0 && _this$$refs$menu.children) {
        var _this$$refs$menu2;
        var _iterator = _createForOfIteratorHelper((_this$$refs$menu2 = this.$refs.menu) === null || _this$$refs$menu2 === void 0 ? void 0 : _this$$refs$menu2.children),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var child = _step.value;
            this.linksWidths.push(Math.ceil(child === null || child === void 0 ? void 0 : child.getBoundingClientRect().width));
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    },
    toggleDropdown: function toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    closeDropdown: function closeDropdown() {
      this.isDropdown = false;
    }
  }
};