var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.isRedesign ? _c(_setup.NLayout, [_c('Nuxt', {
    class: _vm.$style.page
  })], 1) : _c(_setup.DefaultLayout, [_c('Nuxt', {
    class: _vm.$style.page
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };