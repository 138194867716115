import axios from 'axios';
import { errorTable } from '@@/shared/constants/errors';

export function getErrorMessageTyped (
  error: unknown,
  fallback = 'Произошла ошибка. Попробуйте через несколько минут'
): string {
  if (axios.isAxiosError(error)) {
    const status = Number(error.response?.status);
    const errorByStatus = errorTable[status];

    if (errorByStatus != null) {
      return errorByStatus;
    }

    const preparedFallback = error.response?.status
      ? `${ fallback } (${ error.response?.status })`
      : fallback;

    return error.response?.data?.message ||
      error.message ||
      preparedFallback;
  }

  return fallback;
}

export default getErrorMessageTyped;
