var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.SimpleOverlay,
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.handleClick.apply(null, arguments);
      },
      "contextmenu": function contextmenu($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };