// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scoreLabel_gaN9r{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:.4rem;gap:.4rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media only screen and (max-width:767.98px){.scoreLabel_gaN9r{gap:2px;padding:6px}}.large_5FbSW{color:#fcfcfc;font-size:2rem;font-style:normal;font-weight:500;line-height:120%;padding:.6rem 1.2rem}@media only screen and (max-width:1439.98px){.large_5FbSW{font-size:1.4rem;font-weight:500;line-height:116%;padding:.6rem .8rem}}@media only screen and (max-width:767.98px){.large_5FbSW{font-size:14px;padding:6px}}.small_7vkxj{color:#fcfcfc;font-size:1.4rem;font-style:normal;font-weight:500;line-height:120%;padding:.6rem .8rem}@media only screen and (max-width:1439.98px){.small_7vkxj{font-size:1.2rem;line-height:104%;padding:.6rem}}@media only screen and (max-width:767.98px){.small_7vkxj{font-size:12px;padding:6px}}.hint_8Mhdu{color:#fcfcfc;font-size:1.2rem;font-style:normal;font-weight:500;line-height:80%;padding:.4rem .8rem}@media only screen and (max-width:767.98px){.hint_8Mhdu{font-size:12px;padding:4px 8px}}.dark_FXBWY{background:#012345}.dark_FXBWY.large_5FbSW{font-size:1.4rem;font-weight:500;line-height:120%;padding:.6rem .8rem}@media only screen and (max-width:1439.98px){.dark_FXBWY.large_5FbSW{line-height:116%;padding:.6rem}}.primary_lZ6PB{background:-webkit-gradient(linear,left top,right top,from(#92278f),color-stop(57.99%,#c6168d),to(#ed1c24));background:linear-gradient(90deg,#92278f,#c6168d 57.99%,#ed1c24)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreLabel": "scoreLabel_gaN9r",
	"large": "large_5FbSW",
	"small": "small_7vkxj",
	"hint": "hint_8Mhdu",
	"dark": "dark_FXBWY",
	"primary": "primary_lZ6PB"
};
module.exports = ___CSS_LOADER_EXPORT___;
