var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.innerOpened ? _c('div', {
    class: _vm.$style.HeaderQRCodePopup
  }, [_c('div', {
    class: _vm.$style.QRCodePopupBackground,
    on: {
      "click": function click() {
        return _vm.close();
      }
    }
  }), _vm._v(" "), _c('div', {
    class: [_vm.$style.QRCodePopup],
    style: _vm.cssVariables
  }, [_c('p', {
    class: _vm.$style.text
  }, [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]), _vm._v(" "), _c('SButton', {
    class: _vm.$style.acceptButton,
    attrs: {
      "theme": "ghost"
    },
    on: {
      "click": _vm.handleAccept
    }
  }, [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")])], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };