import { getLogger } from '@@/shared/services/Logger';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  reactive,
  set,
  type DefineComponent
} from 'vue';
import { isVue3 } from 'vue-demi';
import { EmitsOptions } from 'vue/types/v3-setup-context';

interface NotificationData {
  component: DefineComponent<any, any, any, any, any, any, any, any, any, any>;
  props: Record<string, any>;
  listeners: Record<string, any>;
  key: string;
}

let lastId = 0;

type ExtractEventFunctors<Emits extends EmitsOptions> = Emits extends Array<string>
? Record<Emits[number], unknown>
: Emits extends Record<string, unknown>
  ? {[K in keyof Emits]: Emits[K]}
  : never

interface ShowNotificationArgs<TProps, TEmits extends EmitsOptions = {}> {
  props: Partial<TProps>;
  listeners: Partial<ExtractEventFunctors<TEmits>>;
}

interface NotificationHandler<TProps> {
  remove(): void;
  setProp<TProp extends keyof TProps>(key: TProp, value: TProps[TProp]): void;
}

class NotificationService {
  readonly notificationsData: Array<NotificationData> = [];

  static create () {
    if (isVue3) {
      getLogger('console').log('warning', '[Vue 2→3] Проверить NotificationHandler на правильную работу!');
    }

    /* сделаем экземпляр реактивным */
    const nonReactive = new NotificationService();
    const result = reactive({ ...nonReactive }) as NotificationService;
    result.showNotification = nonReactive.showNotification.bind(result);

    return result;
  }

  private constructor () { /* просто приватизируем его */ }

  showNotification<TProps, TEmits extends EmitsOptions = {}> (
    component: DefineComponent<any, any, any, any, any, any, any, TEmits, any, TProps>,
    args?: Partial<ShowNotificationArgs<TProps, TEmits>>
  ): NotificationHandler<TProps> {
    const key = `${ component.name ?? 'Anonymous component' }-${ lastId++ }`;
    const data: NotificationData = {
      component,
      props: args?.props ?? {},
      listeners: args?.listeners ?? {},
      key
    };

    this.notificationsData.push(data);

    return {
      remove: () => {
        const index = this.notificationsData.findIndex((data) => data.key === key);

        if (index > -1) {
          this.notificationsData.splice(index, 1);
        }
      },
      setProp: (key, value) => {
        set(data.props, key as string, value);
      }
    };
  }
}

export const notificationService = NotificationService.create();
