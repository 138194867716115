// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderContactsButton_lpRcK{position:relative}.contactsContent_A81c\\+{position:absolute;right:0;top:calc(100% + 1rem);width:29rem;--manager-padding:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderContactsButton": "HeaderContactsButton_lpRcK",
	"contactsContent": "contactsContent_A81c+"
};
module.exports = ___CSS_LOADER_EXPORT___;
