import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    class: _vm.$style.HeaderLinksList
  }, _vm._l(_vm.links, function (link, idx) {
    return _c('li', {
      key: idx,
      class: _vm.$style.row
    }, [_c('a', {
      class: _vm.$style.link,
      attrs: {
        "href": link.href,
        "target": "_blank"
      }
    }, [_c('span', {
      class: _vm.$style.linkLabel
    }, [_c('span', {
      class: _vm.$style.animationWrapper
    }, [_vm._v("\n          " + _vm._s(link.name) + "\n        ")])]), _vm._v(" "), _c('span', {
      class: _vm.$style.iconBackground
    }, [_c('SIcon', {
      class: _vm.$style.icon,
      attrs: {
        "icon": "link",
        "size": "16"
      }
    })], 1)])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };