export const state = () => ({
  images: {
    items: {},
    isFetched: false
  }
});
/* eslint-disable no-extra-parens */
export const getters = {
  getPhoto: (state) => Object.entries(state.images.items).reduce((out, item) => {
    out[item[0]] = item[1].aws;

    return out;
  }, {})
};

export const actions = {
  async getPhotos ({ state, commit }) {
    if (!state.images.isFetched) {
      try {
        const url = this.$api.pages.broker_registration;
        const data = await this.$axios.$get(url);
        commit('SET_IMAGES', data);

        return true;
      } catch (error) {
        console.log('🚀 ~ file: index.js ~~ getPhotos ~ error', error);
        this.$sentry.captureException(error);

        return false;
      }
    } else {
      return true;
      // console.log('images already fetched');
    }
  }
};

export const mutations = {
  SET_IMAGES (state, payload) {
    state.images.items = payload;
    state.images.isFetched = true;
  }
};
