export function lockBody (): void {
  document.documentElement.style.height = '100%';
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
}

export function unlockBody (): void {
  document.documentElement.style.height = '';
  document.body.style.height = '';
  document.body.style.overflow = '';
}

export function cleanPhone (prettyPhoneNumber: string): string {
  return prettyPhoneNumber.replace(/ |-|\(|\)|_/g, '');
}

// eslint-disable-next-line
export function objectToFormData (object: Record<string, any>): FormData {
  if (!object || typeof object !== 'object') {
    console.warn('[objectToFormData] wrong Object');

    return new FormData();
  }

  const data = new FormData();

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      data.append(key, object[key]);
    }
  }

  return data;
}
