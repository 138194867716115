import Vue from 'vue';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';

export interface ContextMenuItem {
  title: string;
  value: string;
  icon: string;
  callback(e: Event): void;
  show?: boolean;
}

export interface ContextMenuOptions {
  attach: HTMLElement | null;
  x: number;
  y: number;
}

interface OpenArgs {
  items: Array<ContextMenuItem>;
  heading: string;
  item: unknown;
  options: Partial<ContextMenuOptions>;
}

const contextMenu = {
  data: Vue.observable({
    show: false,
    options: {
      x: 0,
      y: 0,
      attach: null
    },
    heading: '',
    item: {},
    items: []
  }) as OpenArgs,
  event: new Vue(),
  open (args: OpenArgs): void {
    this.event.$emit('open', args);
  },
  close (): void {
    this.event.$emit('close');
  },
  change<TKey extends keyof OpenArgs> (prop: TKey, value: OpenArgs[TKey]): void {
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.set(this.data, prop, value);
  }
};

interface ContextMenuPlugin {
  open (args: OpenArgs): void;
  close (): void;
  change<TKey extends keyof OpenArgs> (prop: TKey, value: OpenArgs[TKey]): void;
}

export default defineNuxtPlugin(({ app }, inject) => {
  app.$contextMenu = contextMenu;
  inject('contextMenu', contextMenu);
});

declare module '@nuxt/types' {
  interface Context {
    $contextMenu: ContextMenuPlugin;
  }
  interface NuxtAppOptions {
    $contextMenu: ContextMenuPlugin;
  }
}

declare module '@nuxtjs/composition-api' {
  interface Context {
    $contextMenu: ContextMenuPlugin;
  }
  interface NuxtAppOptions {
    $contextMenu: ContextMenuPlugin;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $contextMenu: ContextMenuPlugin;
  }
  interface CreateComponentPublicInstance {
    $contextMenu: ContextMenuPlugin;
  }
}

export function useContextMenu (): ContextMenuPlugin {
  return contextMenu;
}
