// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width:767.98px){.header_2c2pB{border-bottom:1px solid #ccd3da;padding:12px 0;position:relative}}.wrapper_vbT7P{height:100%;padding:5.4rem 5.6rem 0;pointer-events:all}@media only screen and (max-width:1023.98px){.wrapper_vbT7P{padding:40px 40px 0}}@media only screen and (max-width:767.98px){.wrapper_vbT7P{padding:20px 0}}.arrow_3tPiK{display:none;position:absolute;right:0;top:50%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;color:#012345;height:20px;justify-content:center;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:20px}@media only screen and (max-width:767.98px){.arrow_3tPiK{display:-webkit-box;display:-ms-flexbox;display:flex}}.arrowIcon_4SbQn{-ms-flex-negative:0;color:#99a7b5;flex-shrink:0;-webkit-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transition:-webkit-transform .4s ease-in-out;transition:-webkit-transform .4s ease-in-out;transition:transform .4s ease-in-out;transition:transform .4s ease-in-out,-webkit-transform .4s ease-in-out}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_2c2pB",
	"wrapper": "wrapper_vbT7P",
	"arrow": "arrow_3tPiK",
	"arrowIcon": "arrowIcon_4SbQn"
};
module.exports = ___CSS_LOADER_EXPORT___;
