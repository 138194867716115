var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isGold ? _c('svg', {
    attrs: {
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_18037_142622)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.5 23.75H27.5V26.25H2.5V23.75ZM2.5 6.25L8.75 10L15 2.5L21.25 10L27.5 6.25V21.25H2.5V6.25Z",
      "fill": "url(#paint0_linear_18037_142622)"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M2.5 23.75H27.5V26.25H2.5V23.75ZM2.5 6.25L8.75 10L15 2.5L21.25 10L27.5 6.25V21.25H2.5V6.25Z",
      "fill": "url(#paint1_linear_18037_142622)"
    }
  })]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_18037_142622",
      "x1": "38.4375",
      "y1": "12",
      "x2": "-5.83333",
      "y2": "12.2873",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0.232981",
      "stop-color": "#6185A0"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.480739",
      "stop-color": "#C9D5DE"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.770176",
      "stop-color": "#5183A8"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#66A1CC"
    }
  })], 1), _vm._v(" "), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_18037_142622",
      "x1": "2.5",
      "y1": "14.375",
      "x2": "27.5",
      "y2": "14.375",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#DE9F17"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.32",
      "stop-color": "#CF9A15"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.68",
      "stop-color": "#F5ED84"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFCF45"
    }
  })], 1), _vm._v(" "), _c('clipPath', {
    attrs: {
      "id": "clip0_18037_142622"
    }
  }, [_c('rect', {
    attrs: {
      "width": "30",
      "height": "30",
      "fill": "white"
    }
  })])], 1)]) : _vm.isPlatinum ? _c('svg', {
    attrs: {
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_18037_142625)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.5 23.75H27.5V26.25H2.5V23.75ZM2.5 6.25L8.75 10L15 2.5L21.25 10L27.5 6.25V21.25H2.5V6.25Z",
      "fill": "url(#paint0_linear_18037_142625)"
    }
  })]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_18037_142625",
      "x1": "29.7321",
      "y1": "12",
      "x2": "-0.0669638",
      "y2": "12",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0.000928407",
      "stop-color": "#9D9D9D"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.419188",
      "stop-color": "#D9D8D8"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.770176",
      "stop-color": "#666666"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.88",
      "stop-color": "#6C6C6C"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.9",
      "stop-color": "#7E7E7E"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.94",
      "stop-color": "#9D9D9D"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.97",
      "stop-color": "#C6C6C6"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#EFEFEF"
    }
  })], 1), _vm._v(" "), _c('clipPath', {
    attrs: {
      "id": "clip0_18037_142625"
    }
  }, [_c('rect', {
    attrs: {
      "width": "30",
      "height": "30",
      "fill": "white"
    }
  })])], 1)]) : _c('svg', {
    attrs: {
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_18037_142616)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.5 23.75H27.5V26.25H2.5V23.75ZM2.5 6.25L8.75 10L15 2.5L21.25 10L27.5 6.25V21.25H2.5V6.25Z",
      "fill": "url(#paint0_linear_18037_142616)"
    }
  })]), _vm._v(" "), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_18037_142616",
      "x1": "38.4375",
      "y1": "12",
      "x2": "-5.83333",
      "y2": "12.2873",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0.232981",
      "stop-color": "#6185A0"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.480739",
      "stop-color": "#C9D5DE"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "0.770176",
      "stop-color": "#5183A8"
    }
  }), _vm._v(" "), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#66A1CC"
    }
  })], 1), _vm._v(" "), _c('clipPath', {
    attrs: {
      "id": "clip0_18037_142616"
    }
  }, [_c('rect', {
    attrs: {
      "width": "30",
      "height": "30",
      "fill": "white"
    }
  })])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };