var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.HeaderButton, {
    class: _vm.$style.HeaderButtonMenu,
    attrs: {
      "aria-label": "Открыть меню"
    }
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "i-burger-menu",
      "size": "24"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };