import { LocalScheme } from '@nuxtjs/auth-next/dist/runtime';
import { DataLayerService } from '@@/shared/services/DataLayerService';
import {
  getActivePinia
} from 'pinia';
import { clearCityOfSale } from '@@/shared/composables/cityOfSale';
import { useIsomorphicContext } from '@/plugins/app-context';

export default class EmailScheme extends LocalScheme {
  async login (endpoint, {
    reset = true
  } = {}) {
    const response = await super.login(endpoint, {
      reset
    });
    const {
      data
    } = response;

    const roleBackend = {
      agent: 'agents',
      repres: 'represes'
    };

    if (!roleBackend[data.role]) {
      return;
    }

    await this.$auth.setStrategy(roleBackend[data.role]);
    await this.$auth.setUserToken(data.token);
    await this.$auth.fetchUser();

    const dataLayer = new DataLayerService();
    const strategy = this.$auth.$state.strategy;

    const roleSEO = {
      agents: 'agent',
      represes: 'agency',
      users: 'user'
    };

    dataLayer.setUserId(data.id);
    dataLayer.addEvent('login', {
      role: roleSEO[strategy]
    });

    return response;
  }

  resetStore () {
    const nuxtContext = useIsomorphicContext();

    getActivePinia()._s.forEach((store) => {
      if (['deals'].includes(store.$id)) {
        store.$dispose();
        delete nuxtContext.$pinia.state.value[store.$id];
      }
    });
  }

  async logout (endpoint) {
    const response = await super.logout(endpoint).then(() => {
      this.resetStore();
      clearCityOfSale();
    });

    if (process.env.DEVELOPMENT) {
      this.$auth.$storage.removeCookie('_lksession', {
        prefix: '',
        path: '/',
        domain: ''
      });
    }

    return response;
  }
}
