export default {
  props: {
    large: Boolean,
    small: Boolean,
    little: Boolean,
    xLarge: Boolean,
    xxLarge: Boolean,
    xSmall: Boolean,
    xxSmall: Boolean
   
  },

  computed: {
    medium () {
      return Boolean(!this.xSmall &&
            !this.xxSmall &&
            !this.small &&
            !this.little &&
            !this.large &&
            !this.xLarge &&
            !this.xxLarge);
    },

    sizeableClasses () {
      return {
        'v-size--xx-small': this.xxSmall,
        'v-size--x-small': this.xSmall,
        'v-size--small': this.small,
        'v-size--default': this.medium,
        'v-size--large': this.large,
        'v-size--x-large': this.xLarge,
        'v-size--xx-large': this.xxLarge
      };
    }
  }
};
