// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_BSiCa{display:-webkit-box;display:-ms-flexbox;display:flex;min-height:100%}.DefaultLayout_BSiCa,.pageWrapper_LYvUW{position:relative;width:100%}.pageWrapper_LYvUW{padding-top:var(--header-h);-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.pageWrapper_LYvUW.fullHeight_JuKXm{height:100%}.pageWrapper_LYvUW.data-locked-scroll{z-index:20}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DefaultLayout": "DefaultLayout_BSiCa",
	"pageWrapper": "pageWrapper_LYvUW",
	"fullHeight": "fullHeight_JuKXm"
};
module.exports = ___CSS_LOADER_EXPORT___;
