import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';

export const state = () => ({
  documents: []
});
export const actions = {
  async getDocuments ({ commit }, { slug, bookingId }) {
    try {
      const url = this.$api.documents.getQuizDocuments(slug, bookingId);
      const {
        data
      } = await this.$axios.get(url);

      commit('SET_DOCUMENTS_LIST', data);
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ getDocuments ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  async sendPackageDocuments (_, bookingId) {
    try {
      const url = this.$api.documents.sendPackageDocuments(bookingId);
      await this.$axios.$post(url);
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ sendPackageDocuments ~ error', error);
      this.$sentry.captureException(error);
      const notificationData = {
        message: getErrorMessageTyped(error),
        type: 'negative'
      };
      await this.$notify.create(notificationData);
      throw new Error(error);
    }
  },

  async uploadDocuments (_, {
    bookingId,
    id,
    payload
  }) {
    try {
      const url = '/api/v2/documents/upload-documents';
      const { data } = await this.$axios.post(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          bookingId,
          documentId: id
        }
      });

      return data;
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ uploadDocuments ~ error', error);
      this.$sentry.captureException(error);
      const notificationData = {
        message: getErrorMessageTyped(error),
        type: 'negative'
      };
      await this.$notify.create(notificationData);
    }
  },

  async deleteDocument (_, payload) {
    try {
      const {
        ids,
        bookingId,
        documentId
      } = payload;

      const url = '/api/documents/delete-documents';
      await this.$axios.patch(url, {
        file_ids: ids,
        booking_id: bookingId,
        document_id: documentId
      });
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ uploadDocuments ~ error', error);
      this.$sentry.captureException(error);
      throw new Error(error);
    }
  }
};

export const mutations = {
  SET_DOCUMENTS_LIST (state, payload) {
    state.documents = payload;
  }
};
