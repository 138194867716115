import type {
  ActionTree,
  MutationTree
} from 'vuex';

import {
  CalendarEventResponse
} from '@/types/CalendarEvent';

interface RequestCalendarFilters {
  type?: 'event' | 'meeting';
  formatType?: 'online' | 'offline';
  dateStart?: Date;
  dateEnd?: Date;

  showOnlyRecordedEvents?: boolean;
  showOnlyNotRecordedMeetings?: boolean;
  eventCity?: Array<string>;

  meetingStatus?: Array<string>;
  meetingClient?: string;
  bookingId?: number;
}

interface EventsResponse {
  count: number;
  recordCount: number;
  result: Array<CalendarEventResponse>;
}

interface State {
  events: Array<CalendarEventResponse>;
  count?: number;
}

export const state = (): State => ({
  events: [],
  count: undefined
});

/* eslint-disable-next-line */
export type RootState = any;

export const actions: ActionTree<State, RootState> = {
  async getEvents ({ commit, rootState }): Promise<void> {
    try {
      const {
        filters
      } = rootState.calendar.filters;

      if (!filters) {
        return;
      }

      const requestFilters: RequestCalendarFilters = {};
      for (const property in filters) {
        if (filters[property] !== 'all') {
          requestFilters[property as keyof RequestCalendarFilters] = filters[property];
        }
      }

      const {
        data
      } = await this.$axios.get<EventsResponse>('api/calendar', {
        params: {
          ...requestFilters
        }
      });

      commit('SET_EVENTS', data.result);
      commit('SET_COUNT', data.count);
    } catch (error) {
      console.log('🚀 ~ file: calendar.ts ~ getEvents ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  }
};

export const mutations: MutationTree<State> = {
  SET_EVENTS (
    state: State,
    payload: Array<CalendarEventResponse>
  ): void {
    state.events = payload;
  },

  SET_COUNT (
    state: State,
    payload: number
  ): void {
    state.count = payload;
  }
};
