import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable new-cap,no-prototype-builtins,no-unused-expressions,prefer-const */
// Mixins
import Colorable from '../../../mixins/colorable';
import Routable from '../../../mixins/routable';
import { factory as GroupableFactory } from '../../../mixins/groupable';
import Themeable from '../../../mixins/themeable';
import { factory as ToggleableFactory } from '../../../mixins/toggleable';

// Directives
import Ripple from '../../../directives/ripple';

// Utilities
import { keyCodes } from '../../../utils/helpers';
import { removed } from '../../../utils/console';
import mixins from '../../../utils/mixins';
var baseMixins = mixins(Colorable, Routable, Themeable, GroupableFactory('listItemGroup'), ToggleableFactory('inputValue'));
export default baseMixins.extend({
  name: 'VListItem',
  directives: {
    Ripple: Ripple
  },
  inject: {
    isInGroup: {
      default: false
    },
    isInList: {
      default: false
    },
    isInMenu: {
      default: false
    },
    isInNav: {
      default: false
    }
  },
  inheritAttrs: false,
  props: {
    activeClass: {
      type: String,
      default: function _default() {
        if (!this.listItemGroup) {
          return '';
        }
        return this.listItemGroup.activeClass;
      }
    },
    dense: Boolean,
    inactive: Boolean,
    link: Boolean,
    selectable: {
      type: Boolean
    },
    tag: {
      type: String,
      default: 'div'
    },
    threeLine: Boolean,
    twoLine: Boolean,
    value: null
  },
  data: function data() {
    return {
      proxyClass: 'v-list-item--active'
    };
  },
  computed: {
    classes: function classes() {
      return _objectSpread(_objectSpread({
        'v-list-item': true
      }, Routable.options.computed.classes.call(this)), {}, {
        'v-list-item--dense': this.dense,
        'v-list-item--disabled': this.disabled,
        'v-list-item--link': this.isClickable && !this.inactive,
        'v-list-item--selectable': this.selectable,
        'v-list-item--three-line': this.threeLine,
        'v-list-item--two-line': this.twoLine
      }, this.themeClasses);
    },
    isClickable: function isClickable() {
      return Boolean(Routable.options.computed.isClickable.call(this) || this.listItemGroup);
    }
  },
  created: function created() {
    /* istanbul ignore next */
    if (this.$attrs.hasOwnProperty('avatar')) {
      removed('avatar', this);
    }
  },
  methods: {
    click: function click(e) {
      if (e.detail) {
        this.$el.blur();
      }
      this.$emit('click', e);
      this.to || this.toggle();
    },
    genAttrs: function genAttrs() {
      var attrs = _objectSpread({
        'aria-disabled': this.disabled ? true : undefined,
        tabindex: this.isClickable && !this.disabled ? 0 : -1
      }, this.$attrs);
      if (this.$attrs.hasOwnProperty('role')) {
        // do nothing, role already provided
      } else if (this.isInNav) {
        // do nothing, role is inherit
      } else if (this.isInGroup) {
        attrs.role = 'option';
        attrs['aria-selected'] = String(this.isActive);
      } else if (this.isInMenu) {
        attrs.role = this.isClickable ? 'menuitem' : undefined;
        attrs.id = attrs.id || "list-item-".concat(this._uid);
      } else if (this.isInList) {
        attrs.role = 'listitem';
      }
      return attrs;
    }
  },
  render: function render(h) {
    var _this = this;
    var _this$generateRouteLi = this.generateRouteLink(),
      tag = _this$generateRouteLi.tag,
      data = _this$generateRouteLi.data;
    data.attrs = _objectSpread(_objectSpread({}, data.attrs), this.genAttrs());
    data[this.to ? 'nativeOn' : 'on'] = _objectSpread(_objectSpread({}, data[this.to ? 'nativeOn' : 'on']), {}, {
      keydown: function keydown(e) {
        /* istanbul ignore else */
        if (e.keyCode === keyCodes.enter) {
          _this.click(e);
        }
        _this.$emit('keydown', e);
      }
    });
    if (this.inactive) {
      tag = 'div';
    }
    if (this.inactive && this.to) {
      data.on = data.nativeOn;
      delete data.nativeOn;
    }
    var children = this.$scopedSlots.default ? this.$scopedSlots.default({
      active: this.isActive,
      toggle: this.toggle
    }) : this.$slots.default;
    return h(tag, this.setTextColor(this.color, data), children);
  }
});