import { Context } from '@nuxt/types';
import {
  setGlobalPinia,
  useRealtyApp
} from '@strana-artw/realty';
const piniaPlugin = ({ $pinia, app }: Context): void => {
  // eslint-disable-next-line
  $pinia.use(({ store }: { store: any }) => {
    store.$axios = app.$axios;
    store.$router = app.router;
    store.$sentry = app.$sentry;
    store.$api = app.$api;
    store.$portal = app.$portal;
    store.$notify = app.$notify;
  });

  setGlobalPinia($pinia);
  useRealtyApp();
};

export default piniaPlugin;
