// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_rUSlZ{background-color:rgba(0,0,0,.6);color:#000;height:100%;left:0;overflow:hidden;position:fixed;top:0;width:100%;z-index:1000010;-webkit-overflow-scrolling:touch}.overlay_rUSlZ._overflow_aokKb{overflow-y:auto}.overlay_rUSlZ.overlay-appear-enter-active{-webkit-transition:all .4s;transition:all .4s}.overlay_rUSlZ.overlay-appear-leave-active{opacity:0;-webkit-transition:all .2s;transition:all .2s}.overlay_rUSlZ.overlay-appear-enter{opacity:0}.overlay_rUSlZ._transparent-overlay{background:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay_rUSlZ",
	"_overflow": "_overflow_aokKb"
};
module.exports = ___CSS_LOADER_EXPORT___;
