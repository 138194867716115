const newsApi = {
  news: {
    tags: '/api/news/tags',
    all: '/api/news',
    getNewsItem: (slug: string) => `/api/news/${ slug }`,
    getMarkBenefit: (slug: string) => `/api/news/${ slug }/mark_benefit`
  }
};

export default newsApi;

export const newsApiConfig = newsApi.news;
