import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('button', {
    staticClass: "btn-reset",
    class: [_vm.$style.TheHeaderBurger, _defineProperty({}, _vm.$style['is-active'], _vm.isActive)],
    on: {
      "click": _vm.menuOpen
    }
  }, [_c('span', {
    class: _vm.$style.wrapper
  }, [_c('SIcon', {
    class: _vm.$style.menu,
    attrs: {
      "icon": "i-menu",
      "size": "24"
    }
  }), _vm._v(" "), _c('SIcon', {
    class: _vm.$style.close,
    attrs: {
      "icon": "i-close",
      "size": "24"
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };