// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderNavigationInline_lA5Fg{opacity:0;-webkit-transition:opacity .2s ease-out;transition:opacity .2s ease-out;width:100%}.HeaderNavigationInline_lA5Fg.m-show_y1W4u{opacity:1}.dropdownButton_14aoG{opacity:0;-webkit-transition:opacity .2s ease-out;transition:opacity .2s ease-out}.dropdownButton_14aoG.m-show_y1W4u{opacity:1}.link_HsiNk{color:#012345;display:block;font-size:14px;font-weight:500;height:18px;line-height:1.3;padding:0 1.5rem}.link_HsiNk .headerLink__hoverValue{left:1.5rem}.menu_v0UDm{display:-webkit-box;display:-ms-flexbox;display:flex;list-style-type:none;margin-left:-1.5rem;padding:0;white-space:nowrap;width:100%}.menu_v0UDm._firstInit_Vz-ll a,.menu_v0UDm._firstInit_Vz-ll button,.menu_v0UDm._firstInit_Vz-ll span{color:transparent!important}.menu_v0UDm .hiddenMenuItemWrapper_NIE\\+i{margin-bottom:.5rem}.menu_v0UDm .hiddenMenuItemWrapper_NIE\\+i:last-child{margin-bottom:0}.menu_v0UDm .hiddenMenuItemInner_xUr-\\+{padding:0 1.5rem}.menu_v0UDm .columnItem_8HqUK{color:#012345;font-size:14px;font-weight:500;line-height:116%}.menu_v0UDm .columnList_DLFYW{display:-webkit-box;display:-ms-flexbox;display:flex;position:absolute;top:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;background-color:#fcfcfc;border-radius:1.6rem;-webkit-box-shadow:0 0 1.2rem rgba(0,0,0,.06);box-shadow:0 0 1.2rem rgba(0,0,0,.06);-ms-flex-direction:column;flex-direction:column;gap:.5rem;min-width:21.5rem;padding:2rem 2.4rem;-webkit-transition:opacity .4s ease-in-out;transition:opacity .4s ease-in-out}.menu_v0UDm .columnList_DLFYW.m-left_ByvYq{left:0}.menu_v0UDm .columnList_DLFYW.m-right_fsK93{right:0}.columnItem_8HqUK,.link_HsiNk{-webkit-transition:color .2s ease-out;transition:color .2s ease-out}.columnItem_8HqUK:hover,.link_HsiNk:hover{color:#92278f}.dropdownItem_MdUQu{position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderNavigationInline": "HeaderNavigationInline_lA5Fg",
	"m-show": "m-show_y1W4u",
	"dropdownButton": "dropdownButton_14aoG",
	"link": "link_HsiNk",
	"menu": "menu_v0UDm",
	"_firstInit": "_firstInit_Vz-ll",
	"hiddenMenuItemWrapper": "hiddenMenuItemWrapper_NIE+i",
	"hiddenMenuItemInner": "hiddenMenuItemInner_xUr-+",
	"columnItem": "columnItem_8HqUK",
	"columnList": "columnList_DLFYW",
	"m-left": "m-left_ByvYq",
	"m-right": "m-right_fsK93",
	"dropdownItem": "dropdownItem_MdUQu"
};
module.exports = ___CSS_LOADER_EXPORT___;
