import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.NMenu
  }, [_setup.screen.isMobileOrTablet ? _c(_setup.MenuMobileHead, {
    class: _vm.$style.menuMobileHead,
    on: {
      "menu-item-click": function menuItemClick($event) {
        return _setup.emit('menu-item-click');
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('nav', {
    class: _vm.$style.mainNav,
    attrs: {
      "aria-label": "Основная"
    }
  }, [_vm._l(_setup.headerMenu, function (item) {
    var _vm$$route$matched$;
    return _c('NuxtLink', {
      key: item.id,
      class: [_vm.$style.menuItem, _defineProperty({}, _vm.$style.current, (_vm$$route$matched$ = _vm.$route.matched[0]) === null || _vm$$route$matched$ === void 0 ? void 0 : _vm$$route$matched$.regex.test(item.link))],
      attrs: {
        "to": item.link
      },
      nativeOn: {
        "click": function click($event) {
          return _setup.emit('menu-item-click');
        }
      }
    }, [_c('span', {
      class: _vm.$style.icon
    }, [item.icon.aws ? _c(_setup.RemoteSVG, {
      attrs: {
        "url": item.icon.aws
      }
    }) : _vm._e()], 1), _vm._v("\n      " + _vm._s(item.name) + "\n    ")]);
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.externalLinks
  }, _vm._l(_setup.links, function (item) {
    return _c('a', {
      key: item.title,
      class: [_vm.$style.menuItem, _vm.$style.menuItemExtended],
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('span', [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]), _vm._v(" "), _c(_setup.SIcon, {
      class: _vm.$style.menuItemIcon,
      attrs: {
        "icon": "i-link-menu",
        "size": "24"
      }
    })], 1);
  }), 0)], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };