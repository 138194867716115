type StorageNames = 'localStorage' | 'sessionStorage'

type TrySaveToStorageResult = {
  success: true;
} | {
  success: false;
  error: Error;
}

export function trySaveToStorage (type: StorageNames, key: string, value: string): TrySaveToStorageResult {
  try {
    const storage = window[type];
    storage.setItem(key, value);

    return {
      success: true
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}

type TryGetFromStorageResult = {
    success: true;
    value: string | null | undefined;
} | {
    success: false;
    error: Error;
}

export function tryGetFromStorage (type: StorageNames, key: string): TryGetFromStorageResult {
  try {
    const storage = window[type];
    const value = storage.getItem(key);

    return {
      success: true,
      value
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}

export function tryRemoveFromStorage (type: StorageNames, key: string): TrySaveToStorageResult {
  try {
    const storage = window[type];
    storage.removeItem(key);

    return {
      success: true
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}

type TryGetAllKeysResult = {
  success: true;
  keys: Array<string>;
} | {
  success: false;
  error: Error;
}

export function tryGetAllKeys (type: StorageNames): TryGetAllKeysResult {
  try {
    const storage = window[type];
    const keys = Object.keys(storage);

    return {
      success: true,
      keys
    };
  } catch (error: unknown) {
    return {
      success: false,
      error: error as Error
    };
  }
}
