import {
  ILogger,
  LogLevels
} from '../interface';

export abstract class BaseLogger implements ILogger {
  protected logLevelsState = new Map<LogLevels, boolean>()

  constructor (enabledLevels?: Partial<Record<LogLevels, boolean>>) {
    if (enabledLevels) {
      for (const key in enabledLevels) {
        const level = key as LogLevels;
        this.logLevelsState.set(level, enabledLevels[level] || false);
      }
    }
  }

  isEnabled (level: LogLevels): boolean {
    return this.logLevelsState.get(level) || false;
  }

  enable (level: LogLevels): void {
    this.logLevelsState.set(level, true);
  }

  disable (level: LogLevels): void {
    this.logLevelsState.set(level, false);
  }

  abstract log (level: LogLevels, data: Error | string | unknown): void
}
