// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".template_bdY75{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:100vh}.main_ILnBT,.template_bdY75{display:-webkit-box;display:-ms-flexbox;display:flex}.main_ILnBT{height:100%;padding-top:var(--header-h);-webkit-box-flex:1;-ms-flex:1;flex:1}.main_ILnBT.fixedContentWidth_Am68P{margin-left:auto;margin-right:auto;max-width:calc(var(--container-width) + var(--gutter)*2);padding-left:var(--gutter);padding-right:var(--gutter);width:100%}.main_ILnBT.fixedContentWidth_Am68P .aside_Y0tHG{padding-left:0}@media only screen and (max-width:767.98px){.main_ILnBT{padding-top:120px}}@media only screen and (max-width:424.98px){.main_ILnBT{padding-top:92px}}.main_ILnBT .content_chhQB{min-width:0;width:100%}.main_ILnBT .header_Prze7{left:0;position:fixed;top:0}.main_ILnBT .aside_Y0tHG{min-height:calc(100vh - var(--header-h));min-width:var(--aside);position:sticky;top:var(--header-h);width:var(--aside)}@media only screen and (max-width:1023.98px){.main_ILnBT .aside_Y0tHG{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": "template_bdY75",
	"main": "main_ILnBT",
	"fixedContentWidth": "fixedContentWidth_Am68P",
	"aside": "aside_Y0tHG",
	"content": "content_chhQB",
	"header": "header_Prze7"
};
module.exports = ___CSS_LOADER_EXPORT___;
