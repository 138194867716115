import { NotifyPlugin } from '@@/types/notifyPlugin';
import { Context } from '@nuxt/types';

let notifyInstance: NotifyPlugin | null = null;
const notifyPlugin = ({ app }: Context): void => {
  notifyInstance = app.$notify;
};

export default notifyPlugin;

export function useNotify (): Readonly<NotifyPlugin> {
  return notifyInstance!;
}
