import { defineStore } from 'pinia';
import {
  computed,
  ref,
  useContext
} from '@nuxtjs/composition-api';

export type RootState = never;

export const MANUAL_SLUGS = {
  PINNING_HELP: 'manual_pinning_help',
  DEALS_AGENT: 'manual_deals_agent',
  DEALS_REPRES: 'manual_deals_repres',
  DOCUMENTS_AGENT: 'manual_documents_agent',
  DOCUMENTS_REPRES: 'manual_documents_repres',
  CLIENTS_AGENT: 'manual_clients_agent',
  CLIENTS_REPRES: 'manual_clients_repres',
  REGISTRATION_AGENT: 'manual_registration_agent',
  REGISTRATION_REPRES: 'manual_registration_repres',
  WORKING_PARTNERS_ACCOUNT: 'working_partners_account'
} as const;

type SlugKeys = keyof typeof MANUAL_SLUGS
type SlugProps = typeof MANUAL_SLUGS[SlugKeys]
interface Manual {
  label: string;
  link: string;
}

interface ManualResponse {
  slug: SlugProps;
  label: string;
  file: Record<string, string>;
}

type State = Partial<Record<SlugProps, Manual>>;

export const useManualsStore = defineStore('manuals', () => {
  const manuals = ref<State>();
  const {
    $axios,
    $sentry
  } = useContext();

  function isSlugs (slugWithRole: string | SlugKeys): slugWithRole is SlugKeys {
    return (slugWithRole as SlugKeys) !== undefined;
  }

  async function getManual ({
    slug,
    role
  }: { slug: string; role?: string }): Promise<void> {
    const slugWithRole: string = role
      ? `${ slug }_${ role.toUpperCase() }`
      : slug;

    if (isSlugs(slugWithRole)) {
      try {
        const _slug: SlugProps = MANUAL_SLUGS[slugWithRole];

        if (manuals.value?.[_slug]) {
          return;
        }

        const url: string = `api/documents/instructions/${ _slug }`;
        const {
          data
        } = await $axios.get<ManualResponse>(url);
        const {
          slug,
          label,
          file
        } = data;
        const temp: State = {
          ...manuals.value
        };
        temp[slug] = {
          label,
          link: file?.aws
        };
        manuals.value = { ...temp };
      } catch (error) {
        console.error('🚀 ~ file: manuals.ts ~ getManual ~ error', error);
        $sentry.captureException(error);
      }
    }
  }

  const getManualBySlug = computed(() => ({
    slug,
    role
  }: { slug: string; role?: string }): Manual | undefined => {
    const slugWithRole = role
      ? `${ slug }_${ role.toUpperCase() }`
      : slug;

    if (!isSlugs(slugWithRole)) {
      return;
    }

    const _slug: SlugProps = MANUAL_SLUGS[slugWithRole];

    return manuals.value?.[_slug];
  }
  );

  return {
    manuals,
    getManual,
    getManualBySlug
  };
});
