import {
  escapeHTML
} from './helpers';
export function plural(num, postfixes) {
  if (isNaN(num)) {
    return '';
  }

  const cases = [2, 0, 1, 1, 1, 2];
  return postfixes[num % 100 > 4 && num % 100 < 20 ? 2 : cases[Math.min(num % 10, 5)]];
}

export function capitalizeFirstLetter(string) {
  if (!string?.length) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function initial(string) {
  if (!string?.length) {
    return '';
  }
  return string.charAt(0).toUpperCase();
}

export function replaceVariablesInTemplate(str, params) {
  return str.replace(/\{(\d+)\}/g, (match, index) => String(params[Number(index)]));
}

export function userNameWithInitialsFormatted(surname, name, patronymic) {
  // const { surname, name, patronymic } = this.$store.state.auth.user;
  const surnameCapitalized =capitalizeFirstLetter(surname);
  const nameInitial = initial(name);
  const patronymicInitial = initial(patronymic);
  let userName = '';
  if (surnameCapitalized) {
    userName += surnameCapitalized;
  }
  if (nameInitial) {
    userName += ` ${nameInitial}.`;
  }
  if (patronymicInitial) {
    userName += ` ${patronymicInitial}.`;
  }
  return userName;
}

export function getMaskedCharacters(text, searchString) {
  if (!searchString) {
    return text;
  }
  const search = (searchString || '').toString().toLowerCase();

  const index = text.toLowerCase().indexOf(search);

  if (index < 0) {
    return { start: text, middle: '', end: '' };
  }

  const start = text.slice(0, index);
  const middle = text.slice(index, index + search.length);
  const end = text.slice(index + search.length);
  return { start, middle, end };
}
export function genFilteredText(text, searchString) {
  text = text || '';

  if (!searchString) {
    return escapeHTML(text);
  }

  const { start, middle, end } =getMaskedCharacters(text, searchString);

  return `${escapeHTML(start)}${genHighlight(middle)}${escapeHTML(end)}`;
}
export function genHighlight(text) {
  return `<span class="vice--text">${escapeHTML(text)}</span>`;
}
