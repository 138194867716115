// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleOverlay_jvbuN{background-color:rgba(1,35,69,.88);height:100%;left:0;position:fixed;top:0;-webkit-transition-property:opacity;transition-property:opacity;width:100%;will-change:opacity;z-index:30}@media only screen and (max-width:767.98px){.SimpleOverlay_jvbuN{z-index:14}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SimpleOverlay": "SimpleOverlay_jvbuN"
};
module.exports = ___CSS_LOADER_EXPORT___;
