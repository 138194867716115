import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';

type PaymentMethodKeys = 'cash' | 'mortgage' | 'mortgageByDev'
interface Discount {
  percent: number;
  discount: number;
  priceWithDiscount: number;
}

type DiscountResponse = Record<PaymentMethodKeys, Discount>

interface PayloadGetDiscount {
  projectSlug: string;
  price: number;
  originalPrice: number;
}

export const useDiscountStore = defineStore('discount', () => {
  const {
    $axios,
    $sentry
  } = useContext();

  const discount = ref<null | DiscountResponse>(null);

  async function getDiscountByProject (payload: PayloadGetDiscount): Promise<void> {
    try {
      const url: string = 'api/properties/prices';
      const {
        data
      } = await $axios.get<DiscountResponse>(url, {
        params: {
          ...payload
        }
      });

      discount.value = data;
    } catch (error) {
      console.error('🚀 ~ file: discount.ts ~ getDiscountByProject ~ e', error);
      $sentry.captureException(error);
    }
  }

  return {
    discount,
    getDiscountByProject
  };
});
