var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    attrs: {
      "href": "https://artw.ru/"
    }
  }, [_c('svg', {
    attrs: {
      "width": "68",
      "height": "13",
      "viewBox": "0 0 68 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_4058_5978)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.9875 9.49009H9.19156V12.7704H15.6639V-0.162842H10.2716L0.536133 12.7704H5.05681L11.9875 3.00569V9.49009Z",
      "fill": "#FCFCFC"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M43.7483 3.11836V-0.162842H31.458V3.11836H35.8895V12.8372H39.7063V3.11836H43.7483Z",
      "fill": "#FCFCFC"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M48.9879 7.74202V-0.162842H45.3115V12.7704H49.5549L54.926 5.25382V12.8034H58.8011L68.5357 -0.129908H64.0248L58.4302 7.77496V-0.129908H58.4143V-0.162842H54.5834L48.9879 7.74202Z",
      "fill": _vm.wColor
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M25.5953 -0.152442H23.4334L23.4281 -0.162842H18.2822V12.8372H22.0125V9.72669H24.5541L26.6197 12.8372H31.2517L28.4098 9.07409L28.9582 8.61649C29.2391 8.38163 29.4943 8.11643 29.7151 7.82609C29.8846 7.60336 30.0259 7.35809 30.1513 7.09289L30.1531 7.08943C30.3394 6.69336 30.4807 6.27216 30.5805 5.86916L30.5779 5.87956L30.5796 5.87003C30.63 5.66896 30.6679 5.47483 30.6936 5.29283C30.7192 5.10736 30.7333 4.94356 30.7333 4.78583C30.7333 3.48843 30.2423 2.29936 29.4422 1.41882C28.7339 0.637092 27.7793 0.0919582 26.7054 -0.0935085L26.6974 -0.0952418L26.2347 -0.151575L26.2241 -0.152442C26.1799 -0.154175 26.1393 -0.155908 26.0899 -0.156775H26.0872C26.0298 -0.157642 25.9627 -0.158508 25.8973 -0.158508C25.832 -0.158508 25.7684 -0.158508 25.7128 -0.156775H25.7066H25.7039C25.6713 -0.155908 25.6412 -0.155042 25.6121 -0.154175L25.5953 -0.152442ZM26.2135 3.27523H26.2153C26.3963 3.35929 26.5606 3.47976 26.7019 3.63056C26.8476 3.78656 26.9589 3.96249 27.0392 4.15836L27.0419 4.16529L27.0383 4.16009C27.1169 4.35509 27.1611 4.56656 27.1611 4.78409V4.78583V4.78843C27.162 5.02936 27.1143 5.26076 27.0374 5.45316L27.0366 5.45489C26.9597 5.64903 26.844 5.82496 26.7089 5.96623L26.7072 5.96796L26.7036 5.97143C26.5676 6.11183 26.4016 6.22796 26.2223 6.30509C26.0448 6.38309 25.8355 6.42816 25.628 6.42729H25.6298H25.628H25.6253H25.379H23.0078H22.9884H22.0125V3.14869H25.6395C25.8364 3.14783 26.0413 3.19636 26.2135 3.27523Z",
      "fill": "#FCFCFC"
    }
  })]), _vm._v(" "), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_4058_5978"
    }
  }, [_c('rect', {
    attrs: {
      "width": "68",
      "height": "13",
      "fill": "white"
    }
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };