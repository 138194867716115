// Helpers
import { convertToUnit } from '../../utils/helpers';

import Vue from 'vue';

export default Vue.extend({
  name: 'Measurable',

  props: {
    /* eslint-disable vue/require-default-prop,vue/require-prop-types */
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String]
    /* eslint-enable vue/require-default-prop,vue/require-prop-types */
  },

  computed: {
    measurableStyles() {
      const styles = {};

      const height = convertToUnit(this.height);
      const minHeight = convertToUnit(this.minHeight);
      const minWidth = convertToUnit(this.minWidth);
      const maxHeight = convertToUnit(this.maxHeight);
      const maxWidth = convertToUnit(this.maxWidth);
      const width = convertToUnit(this.width);

      if (height) {
        styles.height = height;
      }
      if (minHeight) {
        styles.minHeight = minHeight;
      }
      if (minWidth) {
        styles.minWidth = minWidth;
      }
      if (maxHeight) {
        styles.maxHeight = maxHeight;
      }
      if (maxWidth) {
        styles.maxWidth = maxWidth;
      }
      if (width) {
        styles.width = width;
      }

      return styles;
    }
  }
});
