import { ConsoleLogger } from './implementations/consoleLogger';
import { SentryLogger } from './implementations/sentry';
import { ILogger } from './interface';

/**
 * Чтобы не привязываться к конкретно сентри, будем работать через интерфейс
 */
const main: ILogger = new SentryLogger({
  error: true
});

/**
 * Можно использовать для отключения/включения логирования, например, на проде через .env, например
 * Или же привязаться к featureFlag-у
 */
const consoleLogger = new ConsoleLogger({
  error: true,
  warning: true
});

const loggers = {
  console: consoleLogger,
  main
};

export function getLogger (key: keyof typeof loggers = 'main'): ILogger {
  return loggers[key];
}
