import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import type {
  CityResponse,
  CitySelectType
} from '@/types/Cities';
import { useVuexStore } from '@/plugins/app-context';

interface GlobalCity {
  name: string;
  /** Название в предложном падеже */
  namePrepositional: string;
  slug: string;
  /** GlobalId с портального бэка */
  id: string;
}

type CitySlugToNamePrepositionalMap = Record<string, string | undefined>

export const useCitiesStore = defineStore('cities', () => {
  const rawCities = ref<Array<CityResponse>>([]);
  const cities = computed<Array<CitySelectType>>(
    () => rawCities.value.map(
      (rawCity) => ({
        ...rawCity,
        text: rawCity.name,
        value: rawCity.id,
        citySlug: rawCity.slug
      })
    )
  );

  function getPrepositionalNames () {
    /*
    TODO: [Vue 3] перепроверить работоспособность
    citiesGlobal тянется в корень vuex на этапе nuxtServerInit
    */

    const citiesGlobal: Array<GlobalCity> = useVuexStore()?.getters?.getGlobalCities;
    const result: CitySlugToNamePrepositionalMap = {};

    for (const city of citiesGlobal) {
      result[city.slug] = city.namePrepositional;
    }

    return result;
  }

  const prepositionalNames = ref(getPrepositionalNames());

  const { $axios } = useContext();

  function getCityById (cityId: number | null | undefined): CitySelectType | undefined {
    return cities.value.find(({ id }) => id === cityId);
  }

  function getCityByGlobalId (cityGlobalId: string | null | undefined): CitySelectType | undefined {
    return cities.value.find(({ globalId }) => globalId === cityGlobalId);
  }

  function getCityBySlug (slug: string) {
    return rawCities.value.find((city) => city.slug === slug);
  }

  function getCitiesBySlugs (slugs: Array<string>) {
    return rawCities.value.filter((city) => slugs.includes(city.slug));
  }

  async function getCities (forceReload = false): Promise<void> {
    if (!rawCities.value.length || forceReload) {
      rawCities.value = await $axios.get<Array<CityResponse>>('api/cities').then((response) => response.data);
    }
  }

  return {
    getCityById,
    getCityByGlobalId,
    getCityBySlug,
    getCitiesBySlugs,
    cities,
    rawCities,
    prepositionalNames,
    getPrepositionalNameBySlug (slug: string) {
      return prepositionalNames.value[slug];
    },
    ...decorateApiActions({
      getCities
    }, 'store/cities.ts')
  };
});
