import { SIcon } from '@strana-artw/ui-kit';
export default {
  name: 'HeaderLinksList',
  components: {
    SIcon: SIcon
  },
  props: {
    links: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
};