// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheFooter_zj9EP{background-color:#011529}.wrapperBottom_iiqhh,.wrapperTop_TSHrj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;border-bottom:1px solid #012345;height:96px;justify-content:space-between;padding:0 56px}@media only screen and (max-width:767.98px){.wrapperBottom_iiqhh,.wrapperTop_TSHrj{border-bottom:1px solid hsla(0,0%,99%,.16);height:85px;padding:0 16px}}.text_htF1A{color:#677b8f;font-size:12px;font-weight:400;line-height:1.32}.wrapperBottom_iiqhh{border:none}.logo_s1VNP{height:17.98px;width:151px}@media only screen and (max-width:767.98px){.logo_s1VNP{height:14.29px;width:120px}}.columnWrapper_0GJOy{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.columnWrapper_0GJOy:last-child{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}@media only screen and (max-width:767.98px){.columnWrapper_0GJOy:last-child{-webkit-box-align:start;-ms-flex-align:start;align-items:start}}.arrowDown_Yzt98{-webkit-transform:rotate(-180deg);transform:rotate(-180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheFooter": "TheFooter_zj9EP",
	"wrapperBottom": "wrapperBottom_iiqhh",
	"wrapperTop": "wrapperTop_TSHrj",
	"text": "text_htF1A",
	"logo": "logo_s1VNP",
	"columnWrapper": "columnWrapper_0GJOy",
	"arrowDown": "arrowDown_Yzt98"
};
module.exports = ___CSS_LOADER_EXPORT___;
