import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('VApp', {
    class: _vm.$style.LandingLayout
  }, [_c('TheHeader', {
    attrs: {
      "is-login-button-active": true,
      "is-back-link-active": false
    },
    on: {
      "toggle-menu": _vm.toggleHeader
    }
  }), _vm._v(" "), _c('main', {
    class: [_vm.$style.pageWrapper, _defineProperty({}, _vm.$style.fullHeight, _vm.isFixed)],
    style: {
      paddingTop: _vm.height
    }
  }, [_c('Nuxt', {
    class: _vm.$style.page
  })], 1), _vm._v(" "), _c('client-only', [_c('TheFooter')], 1), _vm._v(" "), _vm.isOpened ? _c('TheBurgerMenu', {
    on: {
      "closed": _vm.toggleHeader
    }
  }) : _vm._e(), _vm._v(" "), _c('TheModal'), _vm._v(" "), _c('TheContextMenu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };