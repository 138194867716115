// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackdropOverlay_XhvSS{background-color:rgba(1,35,69,.88);bottom:0;left:0;position:fixed;right:0;top:0;-webkit-transition-property:opacity;transition-property:opacity;will-change:opacity}.BackdropOverlay_XhvSS.hidden_9-q44{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BackdropOverlay": "BackdropOverlay_XhvSS",
	"hidden": "hidden_9-q44"
};
module.exports = ___CSS_LOADER_EXPORT___;
