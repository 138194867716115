var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.ContactsContent
  }, _vm._l(_setup.managers, function (manager) {
    return _c(_setup.ManagerInfo, {
      key: manager.email,
      class: _vm.$style.managerInfo,
      attrs: {
        "manager": manager
      }
    });
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };