import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', {
    class: _vm.$style.TheHeader,
    attrs: {
      "id": _vm.HEADER_ID,
      "app": "",
      "fixed": ""
    }
  }, [_c('div', {
    class: [_vm.$style.headerInner, _defineProperty({}, _vm.$style._container, _vm.isLandingPage)]
  }, [_c('NuxtLink', {
    class: _vm.$style.logo,
    attrs: {
      "to": "/"
    }
  }, [_c('SLogo')], 1), _vm._v(" "), _c('VFadeTransition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.isLogin && !_vm.isFired ? _c('HeaderWithLogin', {
    key: "logined"
  }) : _c('HeaderWithoutLogin', {
    key: "notlogined",
    attrs: {
      "is-login-button-active": _vm.isLoginButtonActive,
      "is-back-link-active": _vm.isBackLinkActive
    },
    scopedSlots: _vm._u([{
      key: "fired",
      fn: function fn() {
        return [_vm.isFired ? _c('HeaderUserBtn', {
          on: {
            "logout": function logout($event) {
              return _vm.$emit('logout');
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _vm.isLogin && !_vm.isFired ? _c('TheHeaderBurger', {
    class: _vm.$style.menuBtn,
    attrs: {
      "is-open": _vm.isOpened
    },
    on: {
      "clicked": _vm.toggleHeader
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.isLogin && !_vm.isFired ? _c('transition-group', {
    attrs: {
      "name": "card",
      "tag": "div"
    }
  }, _vm._l(_vm.notifications, function (notification) {
    return _c('TopNotification', {
      key: notification.id,
      class: _vm.$style.card,
      attrs: {
        "id": notification.id,
        "type": notification.type.value
      },
      on: {
        "close": _vm.removeNotification
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          return [_vm._v("\n        " + _vm._s(notification.text) + "\n      ")];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };