export default function ({
  store,
  redirect,
  route
}) {
  const {
    isFired
  } = store.state?.auth?.user ?? {};

  if (isFired && route.name !== 'fired') {
    return redirect('/fired');
  }
}
