var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.isCertifiedPartner ? _c('div', {
    class: _vm.$style.CertifiedPartner
  }, [_c('div', {
    class: _vm.$style.wrapper
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "i-certified",
      "size": "36"
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.textWrapper
  }, [_c('span', [_vm._v("сертифицированный ")]), _vm._v(" "), _c('span', [_vm._v("партнёр страны")])])], 1)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };