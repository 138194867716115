import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _typeof from "@babel/runtime/helpers/esm/typeof";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.fixed.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SLoader } from '@strana-artw/ui-kit';
import { factory as groupableFactory } from '../../mixins/groupable';
import { factory as toggleableFactory } from '../../mixins/toggleable';
import Elevatable from '../../mixins/elevatable';
import Positionable from '../../mixins/positionable';
import Routable from '../../mixins/routable';
import Sizeable from '../../mixins/sizeable';
import mixins from '../../utils/mixins';
import { breaking } from '../../utils/console';
import VIcon from './VIcon';
import VSheet from './VSheet';
var baseMixins = mixins(VSheet, Routable, Positionable, Sizeable, groupableFactory('btnToggle'), toggleableFactory('inputValue'));
export default baseMixins.extend({
  name: 'v-btn',
  props: {
    /* eslint-disable vue/require-prop-types */
    activeClass: {
      type: String,
      default: function _default() {
        if (!this.btnToggle) {
          return '';
        }
        return this.btnToggle.activeClass;
      }
    },
    block: Boolean,
    depressed: Boolean,
    fab: Boolean,
    icon: Boolean,
    loading: Boolean,
    outlined: Boolean,
    plain: Boolean,
    retainFocusOnClick: Boolean,
    rounded: Boolean,
    tag: {
      type: String,
      default: 'button'
    },
    text: Boolean,
    tile: Boolean,
    type: {
      type: String,
      default: 'button'
    },
    value: [Boolean, String],
    hoverColor: {
      type: String
    },
    colorPreset: {
      type: String,
      default: 'default'
    },
    content: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    noBg: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: true
    }
    /* eslint-enable vue/require-prop-types */
  },
  data: function data() {
    return {
      proxyClass: 'v-btn--active'
    };
  },
  computed: {
    classes: function classes() {
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_defineProperty({
        'v-btn': true
      }, "v-btn--color-".concat(this.colorPreset), true), Routable.options.computed.classes.call(this)), {}, {
        'v-btn--absolute': this.absolute,
        'v-btn--block': this.block,
        'v-btn--bottom': this.bottom,
        'v-btn--disabled': this.disabled || this.loading,
        'v-btn--is-elevated': this.isElevated,
        'v-btn--fab': this.fab,
        'v-btn--fixed': this.fixed,
        'v-btn--has-bg': this.hasBg,
        'v-btn--no-bg': !this.hasBg,
        'v-btn--icon': this.icon,
        'v-btn--left': this.left,
        'v-btn--loading': this.loading,
        'v-btn--outlined': this.outlined,
        'v-btn--plain': this.plain,
        'v-btn--right': this.right,
        'v-btn--round': this.isRound,
        'v-btn--rounded': this.rounded,
        'v-btn--router': this.to,
        'v-btn--text': this.text,
        'v-btn--tile': this.tile,
        'v-btn--top': this.top,
        'v-btn--has-hover': this.computedHover
      }, this.themeClasses), this.groupClasses), this.elevationClasses), this.sizeableClasses);
    },
    computedElevation: function computedElevation() {
      if (this.disabled) {
        return undefined;
      }
      return Elevatable.options.computed.computedElevation.call(this);
    },
    computedRipple: function computedRipple() {
      var defaultRipple = this.icon || this.fab ? {
        circle: true
      } : true;
      if (this.disabled) {
        return false;
      } else {
        var _this$ripple;
        return (_this$ripple = this.ripple) !== null && _this$ripple !== void 0 ? _this$ripple : defaultRipple;
      }
    },
    computedHover: function computedHover() {
      if (this.disabled || this.loading || this.tile || !this.hover) {
        return false;
      }
      var isCircle = Boolean(this.icon || this.fab);
      return isCircle ? {
        circle: true
      } : true;
    },
    hasBg: function hasBg() {
      return !this.noBg && !this.text && !this.plain && !this.outlined && !this.icon;
    },
    isElevated: function isElevated() {
      return Boolean(!this.icon && !this.text && !this.outlined && !this.depressed && !this.disabled && !this.plain && (this.elevation === null || Number(this.elevation) > 0));
    },
    isRound: function isRound() {
      return Boolean(this.icon || this.fab);
    },
    styles: function styles() {
      return _objectSpread({}, this.measurableStyles);
    }
  },
  created: function created() {
    var _this = this;
    var breakingProps = [['flat', 'text'], ['outline', 'outlined'], ['round', 'rounded']];

    /* istanbul ignore next */
    breakingProps.forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        original = _ref2[0],
        replacement = _ref2[1];
      /* eslint-disable no-prototype-builtins */
      if (_this.$attrs.hasOwnProperty(original)) {
        breaking(original, replacement, _this);
      }
      /* eslint-enable no-prototype-builtins */
    });
  },
  methods: {
    click: function click(e) {
      if (!this.retainFocusOnClick && !this.fab && e.detail) {
        this.$el.blur();
      }
      this.$emit('click', e);
      if (this.btnToggle) {
        this.toggle();
      }
    },
    genContent: function genContent() {
      var content;
      if (this.icon && this.iconName) {
        content = this.$createElement(VIcon, {
          staticClass: 'v-btn__content',
          props: {
            name: this.iconName
          }
        }, this.iconName);
      } else {
        content = this.$createElement('span', {
          staticClass: 'v-btn__content'
        }, this.$slots.default || this.content);
      }
      return content;
    },
    genLoader: function genLoader() {
      return this.$createElement('span', {
        class: 'v-btn__loader'
      }, this.$slots.loader || [this.$createElement(SLoader, {
        props: {
          size: 23
        }
      })]);
    }
  },
  render: function render(h) {
    var children = [this.genContent(), this.loading && this.genLoader()];
    var _this$generateRouteLi = this.generateRouteLink(),
      tag = _this$generateRouteLi.tag,
      data = _this$generateRouteLi.data;
    var setColor = this.hasBg ? this.setBackgroundColor : this.setTextColor;
    if (tag === 'button') {
      data.attrs.type = this.type;
      data.attrs.disabled = this.disabled;
    }
    data.attrs.value = ['string', 'number'].includes(_typeof(this.value)) ? this.value : JSON.stringify(this.value);
    data.directives.push({
      name: 'hover',
      value: this.computedHover
    });
    return h(tag, this.disabled ? data : setColor(this.color, data), children);
  }
});