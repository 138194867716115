import { SButton } from '@strana-artw/ui-kit';
export default {
  components: {
    SButton: SButton
  },
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    errorTitle: function errorTitle() {
      return this.error.statusCode === 503 ? 'Тех. работы' : this.error.statusCode;
    },
    errorText: function errorText() {
      var code = this.error.statusCode;
      if (code === 404) {
        return 'Такой страницы не существует или мы ее еще не построили...';
      } else if (code === 503) {
        return 'Мы делаем сайт лучше, осталось немного. Попробуйте вернуться позже... ';
      }
      return 'Произошла внутренняя ошибка сервера, требуется перезагрузка...';
    }
  }
};