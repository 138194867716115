var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.svgContent ? _c('span', {
    class: _vm.$style.icon,
    domProps: {
      "innerHTML": _vm._s(_vm.svgContent)
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };