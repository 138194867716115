import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "header-navigation-inline",
    class: [_vm.$style.HeaderNavigationInline, _vm.displayClasses],
    attrs: {
      "id": "header-navigation-inline"
    }
  }, [_c('menu', {
    ref: "menu",
    class: _vm.$style.menu
  }, [_vm._l(_vm.inlineLinks, function (_ref) {
    var link = _ref.link,
      name = _ref.name;
    return _c('li', {
      key: name
    }, [_c('HoverableLink', {
      class: _vm.$style.link,
      style: {
        height: '18px',
        lineHeight: '1.3'
      },
      attrs: {
        "to": link,
        "text": name,
        "font-size": "14px"
      },
      scopedSlots: _vm._u([{
        key: "text",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(name) + "\n        ")];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._v(" "), _c('li', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: _vm.$style.dropdownItem
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isButtonShown,
      expression: "isButtonShown"
    }],
    ref: "dropdown-button",
    class: [_vm.$style.link, _vm.$style.dropdownButton, _vm.displayClasses],
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleDropdown.apply(null, arguments);
      }
    }
  }, [_vm._v("\n        Еще\n      ")]), _vm._v(" "), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDropdown,
      expression: "isDropdown"
    }],
    class: [_vm.$style.columnList, _vm.dropdownClasses]
  }, _vm._l(_vm.dropdownLinks, function (_ref2) {
    var link = _ref2.link,
      name = _ref2.name;
    return _c('li', {
      key: name,
      class: _vm.$style.columnItem
    }, [_c('NuxtLink', {
      attrs: {
        "to": link
      }
    }, [_vm._v("\n            " + _vm._s(name) + "\n          ")])], 1);
  }), 0)])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };