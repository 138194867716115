import { defineStore } from 'pinia';

import type {
  StateChanger
} from 'vue-infinite-loading';
import {
  Agency
} from '@/types/Agencies';
import {
  PageInfo
} from '@/types/PageInfo';

interface AgenciesResponse {
  count: number;
  'page_info': PageInfo;
  result: Array<Agency>;
}

interface State {
  agencies: Array<Agency>;
  pageInfo: object;
  count: number;
}

export const useAgenciesStore = defineStore('agencies', {
  state: (): State => ({
    agencies: [],
    pageInfo: {},
    count: 0
  }),

  actions: {
    async getAgencies (params: object): Promise<void> {
      try {
        const {
          role
        } = this.$router.app.$store.getters ?? {};
        const {
          agencyGetContractData
        } = this.$api.account.agencies;
        const url = agencyGetContractData(role);
        const {
          data: {
            result,
            count,
            page_info: pageInfo
          }
        } = await this.$axios.get<AgenciesResponse>(url, {
          params
        });

        this.agencies = result;
        this.count = count;
        this.pageInfo = pageInfo;
      } catch (error: unknown) {
        console.log('🚀 ~ file: agencies.ts ~ getAgencies ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState
      }: {
        page: PageInfo;
        infiniteState: StateChanger;
      }) {
      try {
        if (!page?.next_page) {
          return;
        }

        const {
          data: {
            result,
            page_info: pageInfo
          }
        } = await this.$axios.get<AgenciesResponse>(page?.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.agencies = [
            ...this.agencies,
            ...result
          ];
          this.pageInfo = pageInfo;

          if (!pageInfo?.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error: unknown) {
        console.log('🚀 ~ file: agencies.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    }
  }
});
