// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderWithLogin_zqZDj{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1;flex:1;margin-left:6.4rem;min-width:0;width:100%}@media only screen and (max-width:1279.98px){.HeaderWithLogin_zqZDj{margin-left:4rem}}@media only screen and (max-width:1023.98px){.HeaderWithLogin_zqZDj{margin-left:auto}}.HeaderWithLogin_zqZDj._menu_VQsOz{-webkit-box-flex:0;-ms-flex:none;flex:none;margin-left:auto}.linkWrapper_y-E-8{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-flex:1;-ms-flex:1;flex:1;min-width:0}@media only screen and (max-width:1023.98px){.linkWrapper_y-E-8{display:none}}.link_2thHy{margin-right:3.2rem}@media only screen and (max-width:1279.98px){.link_2thHy{margin-right:2.4rem}}.rightMenuWrapper_0uQWG{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:2.5rem}@media only screen and (max-width:1023.98px){.rightMenuWrapper_0uQWG{margin-left:auto}}@media only screen and (max-width:767.98px){.hideMobile_5NnBc{display:none}}.btn_k4LvH{color:#344f6a}.btn_k4LvH+.btn_k4LvH{margin-left:.5rem}.btn_k4LvH:hover{color:#677b8f}.item_DKxiu{margin-right:1.4rem}.item_DKxiu:last-child{margin-right:0}.faqLink_Z\\+XcW{color:#92278f;margin:0 .8rem;opacity:1;-webkit-transition:opacity .3s ease-in-out;transition:opacity .3s ease-in-out}.faqLink_Z\\+XcW:hover{opacity:.7}@media only screen and (max-width:767.98px){.faqLink_Z\\+XcW{margin:0 .4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderWithLogin": "HeaderWithLogin_zqZDj",
	"_menu": "_menu_VQsOz",
	"linkWrapper": "linkWrapper_y-E-8",
	"link": "link_2thHy",
	"rightMenuWrapper": "rightMenuWrapper_0uQWG",
	"hideMobile": "hideMobile_5NnBc",
	"btn": "btn_k4LvH",
	"item": "item_DKxiu",
	"faqLink": "faqLink_Z+XcW"
};
module.exports = ___CSS_LOADER_EXPORT___;
