// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_VhRM-{display:-webkit-box;display:-ms-flexbox;display:flex;gap:1.6rem;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.text_KN7\\+s{color:#012345;font-size:14px;line-height:1.16}.role_0XCq6,.text_KN7\\+s{font-weight:500}.role_0XCq6{color:#677b8f;font-size:10px;letter-spacing:1px;line-height:1.46;text-transform:uppercase}@media only screen and (max-width:767.98px){.hideMobile_G9i-Q{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_VhRM-",
	"text": "text_KN7+s",
	"role": "role_0XCq6",
	"hideMobile": "hideMobile_G9i-Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
