import Vue, {
  type UnwrapNestedRefs,
  reactive
} from 'vue';
import {
  VueScreen,
  type ScreenType
} from '@strana-artw/common';

declare module 'vue/types/vue' {
  interface Vue {
    // eslint-disable-next-line
    // @ts-ignore
    $screen: ScreenType;
  }
}

interface Device {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}

export const SCREEN_BREAKPOINTS = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920
} as const;

let screenPlugin: UnwrapNestedRefs<ScreenType> | undefined;

export default ({ $device = {} satisfies Device }): void => {
  Vue.use({ install: VueScreen.install }, {
    device: $device,
    breakpoints: SCREEN_BREAKPOINTS
  });

  screenPlugin = reactive(Vue.prototype.$screen);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useScreenPlugin = () => {
  if (!screenPlugin) {
    throw new Error('Screen plugin is not defined.');
  }

  return screenPlugin;
};
