import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "rootRef",
    class: [_setup.$style.BackdropOverlay, _defineProperty({}, _setup.$style.hidden, _setup.isHidden)]
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };