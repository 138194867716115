// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row_td7S7{border-bottom:1px solid #ccd3da;margin-bottom:1.2rem;padding-bottom:1.2rem}@media only screen and (max-width:767.98px){.row_td7S7{margin-bottom:0;padding:14px 0}}.row_td7S7:last-child{border-bottom:none;margin-bottom:0}.row_td7S7:last-child .link_\\+Tanh:after{content:none}.link_\\+Tanh{color:#012345;display:block;font-size:1.6rem;font-weight:500;line-height:1.45;position:relative;width:100%}@media only screen and (max-width:1023.98px){.link_\\+Tanh{font-size:1.6rem;line-height:normal}}@media(min-width:768px)and (hover),(min-width:768px)and (min-width:0\\0){.link_\\+Tanh:hover{color:#92278f}.link_\\+Tanh:hover:after{-webkit-transform:scaleX(1);transform:scaleX(1)}}.link_\\+Tanh:after{background-color:currentcolor;bottom:calc(-1.2rem + -1px);content:\"\";height:1px;left:0;position:absolute;-webkit-transform:scaleX(0);transform:scaleX(0);-webkit-transform-origin:center;transform-origin:center;-webkit-transition:color .4s ease-in-out,-webkit-transform .4s ease-in-out;transition:color .4s ease-in-out,-webkit-transform .4s ease-in-out;transition:transform .4s ease-in-out,color .4s ease-in-out;transition:transform .4s ease-in-out,color .4s ease-in-out,-webkit-transform .4s ease-in-out;width:100%}.link_\\+Tanh .iconBackground_I20Wb{position:absolute;right:0;top:50%;-webkit-transform:translate(40%,-50%);transform:translate(40%,-50%);-webkit-transition:fill .4s ease-in-out;transition:fill .4s ease-in-out}@media only screen and (max-width:767.98px){.link_\\+Tanh .iconBackground_I20Wb{right:8px}}.linkLabel_RzE53{color:#012345;display:inline-block;font-size:1.6rem;overflow:hidden}@media only screen and (max-width:1023.98px){.linkLabel_RzE53{font-size:16px}}@media only screen and (max-width:767.98px){.linkLabel_RzE53{font-size:20px}}.iconBackground_I20Wb{background:#f4f5f8;border-radius:50%;height:24px;width:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row_td7S7",
	"link": "link_+Tanh",
	"iconBackground": "iconBackground_I20Wb",
	"linkLabel": "linkLabel_RzE53"
};
module.exports = ___CSS_LOADER_EXPORT___;
