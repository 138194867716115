let modalPlugin;

const Plugin = {
  install (Vue) {
    if (Vue.prototype.$modalIframe) {
      return;
    }

    modalPlugin = {
      event: new Vue(),

      open (component, data) {
        this.event.$emit('open', component, data);
      },

      close () {
        this.event.$emit('close');
      }
    };

    Object.defineProperty(Vue.prototype, '$modalIframe', {
      get () {
        return modalPlugin;
      }
    });
  }
};

export default Plugin;

export const useModalPlugin = () => modalPlugin;
