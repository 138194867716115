import { getLogger } from '@@/shared/services/Logger';

export function fullLog (error: unknown, message: string): void {
  getLogger().log('error', error);
  getLogger('console').log('error', [message, '\n', error]);
}

type TFunction = ((...args: Array<never>) => unknown)

/**
 * Декорирует функцию try-catch блоком, позволяет просунуть колбэк на ошибку
 * @param fn целевая функция
 * @param onCatch колбэк на отлов ошибки
 * @returns декорированную функцию
 */
export function tryCatchDecorate<TFn extends TFunction> (fn: TFn, onCatch: (error: unknown) => void) {
  return (async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      onCatch(error);
    }
  }) as TFn;
}

/**
 * Задекорирует функцию на логирование ошибки при помощи {@link fullLog}
 * @param fn целевая функция
 * @param message сообщение для консоли (традиционно здесь примерно такое → `🚀 ~ file: /news/index.vue ~ getNextPage ~ error`)
 * @param rethrow выбрасывать ли исключение после логирования дальше
 */
export function fullLogDecorate<TFn extends TFunction> (fn: TFn, message: string, rethrow = false): TFn {
  return rethrow
    ? tryCatchDecorate(fn, (error) => {
      fullLog(error, message);
      throw error;
    })
    : tryCatchDecorate(fn, (error) => fullLog(error, message));
}

/**
 * Задекорирует все функции в полях `actions` при помощи {@link fullLogDecorate} на логирование {@link fullLog}
 * @param actions объект с методами, которые нужно задекорировать
 * @param filename для удобства отладки - имя файла, где используется
 * @param rethrow выбрасывать ли исключение после логирования дальше
 */
export function decorateApiActions<TActions extends Record<string, TFunction>> (actions: TActions, filename: string, rethrow = false): TActions {
  const decorated = {} as TActions;
  for (const key in actions) {
    decorated[key] = fullLogDecorate(actions[key], `🚀 ~ file: ${ filename } ~ ${ key } ~ error`, rethrow);
  }

  return decorated;
}
