var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.$style.TopNotification, _vm.$style[_vm.type]]
  }, [_c('div', {
    class: _vm.$style.leftIcon
  }, [_vm._t("leftIcon", function () {
    return [_c('div', {
      class: [_vm.$style.btn, _vm.$style["btn-".concat(_vm.type)]]
    }, [_c('SIcon', {
      style: "color:".concat(_vm.iconsColors.iconColor),
      attrs: {
        "icon": "i-info",
        "size": "24"
      }
    })], 1)];
  }, {
    "close": _vm.close,
    "colors": _vm.iconsColors
  })], 2), _vm._v(" "), _c('div', {
    class: _vm.$style.message
  }, [_vm._t("default")], 2), _vm._v(" "), _c('div', {
    class: [_vm.$style.rightIcon]
  }, [_vm._t("rightIcon", function () {
    return [_c('SButton', {
      class: [_vm.$style.btn, _vm.$style["btn-".concat(_vm.type)]],
      attrs: {
        "equal": ""
      },
      on: {
        "click": _vm.close
      }
    }, [_c('SIcon', {
      attrs: {
        "color": _vm.iconsColors.closeIconColor,
        "icon": "close",
        "size": "16"
      }
    })], 1)];
  }, {
    "close": _vm.close,
    "colors": _vm.iconsColors
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };