import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "appear": "",
      "name": "menu",
      "duration": 1000
    }
  }, [_vm.show ? _c('div', {
    class: [_vm.$style.TheBurgerMenu, _vm.classes]
  }, [_c('SimpleOverlay', {
    key: "overlay",
    ref: "overlay",
    class: _vm.$style.overlay,
    attrs: {
      "id": "burger-menu-overlay"
    },
    on: {
      "clicked": _vm.toggleHeader
    }
  }), _vm._v(" "), _c('div', {
    key: "body",
    class: _vm.$style.body,
    attrs: {
      "id": _vm.BURGER_MENU_ID
    }
  }, [!_vm.isMobile ? _c('div', {
    class: _vm.$style.head
  }, [_c('HeaderUserInfo', {
    class: _vm.$style.menu
  }), _vm._v(" "), _c('TheHeaderBurger', {
    class: _vm.$style.closeButton,
    attrs: {
      "is-open": ""
    },
    on: {
      "clicked": _vm.toggleHeader
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isMobile ? _c('HeaderUserDropdown', {
    on: {
      "logout": _vm.handleClose
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.$style.content
  }, [_vm._l(_vm.menuItems, function (item, idx) {
    return _c('HeaderMenuItem', {
      key: idx,
      class: _vm.$style.cell,
      nativeOn: {
        "mouseenter": function mouseenter($event) {
          return _vm.handleMouseEnter(item.link);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.handleMouseLeave.apply(null, arguments);
        }
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('n-link', {
            class: [_vm.$style.mainLink, _defineProperty({}, _vm.$style._blured, _vm.hoveredLink && _vm.hoveredLink !== item.link)],
            attrs: {
              "to": item.link
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.handleClose.apply(null, arguments);
              }
            }
          }, [_c('span', {
            class: _vm.$style.animationWrapper
          }, [_vm._v("\n                " + _vm._s(item.name) + "\n              ")])])];
        },
        proxy: true
      }], null, true)
    });
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.btns
  }, [_c('SLink', {
    class: [_vm.$style.btn, _vm.$style._projects],
    attrs: {
      "to": "https://strana.com/projects/",
      "target": "_blank"
    }
  }, [_c('SButton', {
    class: _vm.$style.buttonVisual,
    attrs: {
      "theme": "ghost",
      "only-visual": ""
    }
  }, [_c('SIcon', {
    class: _vm.$style.btnIcon,
    attrs: {
      "icon": "chevron-down",
      "size": "16"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.btnText
  }, [_vm._v("\n                Проекты\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.btnBg
  })], 1)], 1), _vm._v(" "), _c('SLink', {
    class: [_vm.$style.btn, _vm.$style._flats],
    attrs: {
      "to": "https://strana.com/flats/",
      "target": "_blank"
    }
  }, [_c('SButton', {
    class: _vm.$style.buttonVisual,
    attrs: {
      "theme": "ghost",
      "only-visual": ""
    }
  }, [_c('SIcon', {
    class: _vm.$style.btnIcon,
    attrs: {
      "icon": "chevron-down",
      "size": "16"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.btnText
  }, [_vm._v("\n                Квартиры\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.btnBg
  })], 1)], 1), _vm._v(" "), _c('SLink', {
    class: [_vm.$style.btn, _vm.$style._commercial],
    attrs: {
      "to": "https://strana.com/commercial/",
      "target": "_blank"
    }
  }, [_c('SButton', {
    class: _vm.$style.buttonVisual,
    attrs: {
      "theme": "ghost",
      "only-visual": ""
    }
  }, [_c('SIcon', {
    class: _vm.$style.btnIcon,
    attrs: {
      "icon": "chevron-down",
      "size": "16"
    }
  }), _vm._v(" "), _c('span', {
    class: _vm.$style.btnText
  }, [_vm._v("\n                Коммерция\n              ")])], 1)], 1)], 1), _vm._v(" "), _c('HeaderLinkList', {
    class: _vm.$style.links,
    attrs: {
      "links": _vm.links
    }
  })], 2)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };