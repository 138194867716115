import {
  pageScrollService
} from '@@/shared/services/PageScrollService';
import {
  BURGER_MENU_ID
} from '@@/shared/constants/app-ids';

export const state = () => ({
  header: {
    isOpened: false
  }
});

export const getters = {
  getHeaderState: (state) => state.header.isOpened
};

export const actions = {
  toggleHeader ({
    state,
    commit
  }) {
    commit('SET_HEADER_STATE', !state.header.isOpened);
  }
};

export const mutations = {
  SET_HEADER_STATE (state, payload) {
    state.header.isOpened = payload;
    const scrollableMenu = document.getElementById(BURGER_MENU_ID);

    if (payload) {
      pageScrollService.lock([scrollableMenu]);
    } else {
      pageScrollService.unlock([scrollableMenu]);
    }
  }
};
