/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error FIXME нужно как-то исправить отсутствие structuredClone в 20.10 версии ноды (мб это только у меня?) (должна быть с 17 версии, но нету)
import structuredClone from 'core-js/actual/structured-clone';
import {
  isNavigationFailure,
  NavigationFailureType,
  type Route
} from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { Context } from '@nuxt/types';
import { isVue2 } from 'vue-demi';
import {
  defineNuxtPlugin,
  onGlobalSetup
} from '@nuxtjs/composition-api';
import { getLogger } from '@@/shared/services/Logger';

type Router = ReturnType<typeof useRouter>
type PushOrReplace = Router['push'] | Router['replace']

// https://github.com/vuejs/vue-router/blob/2642587da9c48b9386bf381608bc2f041d06c10e/types/router.d.ts#L97
function promisify<T extends PushOrReplace> (fn: T): T {
  const result: T = (function (to, onComplete, onAbort) {
    // @ts-expect-error нам нужен this
    // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
    const thisAlias = this;

    // Если вызвано с коллбэками, то согласно типизации возвращаем void
    if (onComplete || onAbort) {
      return fn.call(thisAlias, to, onComplete, onAbort);
    }

    const promise = new Promise<Route>(function (resolve, reject) {
      fn.call(
        thisAlias,
        to,
        (route) => resolve(route),
        (err) => reject(err)
      );
    });

    return promise;
  }) as T;

  return result;
}

/**
 * [Only Nuxt 2] Vue Router push/replace monkey-patch
 *
 * Исправляет на месте несогласованность типов и реализации push/replace у роутера
 *
 * Ошибка вызвана monkey-patch-ем в Nuxt 2 → https://github.com/nuxt/nuxt/blob/e265ef39d4e96b13a2cb3475731a3d794a18429c/packages/vue-app/template/router.js#L110C1-L110C74
 *
 * Глушат промисификацию роутера, передав emptyFn
 */
function monkeyPatchVueRouter (): void {
  if (isVue2) {
    onGlobalSetup(() => {
      const router = useRouter();
      router.push = promisify(router.push);
      router.replace = promisify(router.replace);
    });
  } else {
    getLogger('console').log('warning', '[/plugins/polyfills.ts] Remove this monkey-patch');
  }
}

function decorateIgnoreFailureByRedirect<T extends PushOrReplace> (fn: T, resolver: Router['resolve']): T {
  const result: T = (function (to, onComplete, onAbort) {
    // @ts-expect-error нам нужен this
    // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
    const thisAlias = this;

    if (onComplete || onAbort) {
      return fn.call(thisAlias, to, onComplete, onAbort);
    }

    // fn.call почему-то не шмог вывести правильный тип по аргументам
    const promise = fn.call(thisAlias, to) as unknown as Promise<Route>;

    return promise.catch((e, ...args) => {
      if (isNavigationFailure(e, NavigationFailureType.redirected)) {
        getLogger('console').log('log', ['supressed NavigationFailureType.redirected', e]);

        return;
      }
      throw e;
    }).then(() => resolver(to));
  } as T);

  return result;
}

/**
 * Патчим push и replace на игнор прерываний переходов по причине redirected.
 *
 * В нормальной реализации нам следовало бы обложиться во всех местах с push/replace обработчиками ошибок
 * (см. https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a )
 *
 * Но т.к. это не обработано даже в глубине Nuxt 2, и запатчить это не получится (export-ированная функция), то нам остаётся только заглушить это.
 *
 * Пока подойдёт такой подход, т.к. в случае перехода на Vue 3 нам всё равно нужно будет как минимум проверить (как максимум, переделать) все места с push/replace. ↓
 *
 * см. типизации:
 * * vue 3 https://github.com/vuejs/router/blob/e2272bb166272c7fbea857d711a36dea08be578a/packages/router/src/router.ts#L274
 * * vue 2 https://github.com/vuejs/vue-router/blob/2642587da9c48b9386bf381608bc2f041d06c10e/types/router.d.ts#L97
 *
 */
function patchIgnoreFailureByRedirect (): void {
  onGlobalSetup(() => {
    const router = useRouter();
    router.push = decorateIgnoreFailureByRedirect(router.push, router.resolve.bind(router));
    router.replace = decorateIgnoreFailureByRedirect(router.replace, router.resolve.bind(router));
  });

  if (!isVue2) {
    getLogger('console').log('warning', 'Проверить, нужен ли этот патч');
  }
}

export default defineNuxtPlugin((_nuxtApp: Context) => {
  if (!('structuredClone' in globalThis)) {
    globalThis.structuredClone = structuredClone;
  }
  monkeyPatchVueRouter();
  patchIgnoreFailureByRedirect();
});
