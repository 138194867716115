var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("h".concat(_vm.semanticHeadLevel), {
    tag: "component",
    class: [_vm.$style.NTitle, _vm.$style["h".concat(_vm.styleHeadLevel == null ? _vm.semanticHeadLevel : _vm.styleHeadLevel)]]
  }, [_vm._t("default", function () {
    return [_vm._v("\n    " + _vm._s(_setup.props.title) + "\n  ")];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };