// eslint-disable-next-line
// @ts-nocheck
import MockAdapter from 'axios-mock-adapter';
import { Context } from '@nuxt/types';
import { Response } from './types';
import { responses } from './responses';


export default ({ $axios, isDev }:Context):void => {
  if (!isDev) {
    return;
  }

  const mock:MockAdapter = new MockAdapter($axios);


  responses.forEach(([method, url, statusCode, data]:Response): void => {
    mock[method](url).reply(statusCode, data ?? null);
  });

  mock.onAny()
    .passThrough();
};
