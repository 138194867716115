/**
   * чтобы на локальной сборке тоже работало переключение страниц через next_page в ответе
  */
export function prepareNextPageUrl (absoluteUrl: string) {
  if (process.env.NODE_ENV === 'development') {
    return absoluteUrl.slice(absoluteUrl.indexOf('.com') + 4);
  }

  return absoluteUrl;
}
