import type {
  GetterTree,
  ActionTree,
  MutationTree
} from 'vuex';

import axios from 'axios';

interface User {
  id: number;
  name: string;
  surname: string;
  patronymic: string;
  phone: string;
  email: string;
}

interface ActionsStatus {
  id: number;
  name: string;
  slug: string;
  roleId: number;
}

interface CrmStatus {
  id: number;
  name: string;
  color: string | null;
  stepsNumbers: number;
  currentStep: number;
  actions?: Array<ActionsStatus> | null;
}

interface Projects {
  id: number;
  globalId: string;
  slug: string;
  name: string;
  city: string;
  status: {
    value: string;
    label: string;
  };
}

interface Floor {
  id: number;
  number: string;
}

interface Property {
  id: number;
  price: number;
  rooms: number;
  number: string;
  article: string;
  area: number;
  discount: number | null;
  globalId: string;
  backendId: string;
  originalPrice: number;
  finalPrice: number;
  floor: Floor;
  type: string | null;
}

interface Deal {
  id: number;
  amocrmId: number;
  paymentAmount: number | null;
  finalPaymentAmount: number;
  expires?: string;
  until?: string;
  user: User;
  amocrmStatus: CrmStatus;
  project: Projects;
  property: Property;
}

interface State {
  deals?: Array<Deal>;
  currentAgent?: number;
}

export const state = (): State => ({
  deals: undefined,
  currentAgent: undefined
});

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {};

export const actions: ActionTree<RootState, RootState> = {
  setCurrentAgent ({ commit }, payload) {
    commit('SET_CURRENT_AGENT', payload);
  },
  async agentFired ({ commit }, id: number) {
    try {
      if (id) {
        const role = this.$auth.$state.strategy;
        const url = this.$api.fire.byRole(role);
        const { bookings } = await this.$axios.$patch(url, null, {
          params: {
            agent_id: id
          }
        });
        commit('SET_DEALS', bookings);
      } else {
        const url = this.$api.fire.agent;
        await this.$axios.$patch(url);
        await this.$auth.fetchUser();
      }
    } catch (error: unknown) {
      this.$sentry.captureException(error);
      const message =
      axios.isAxiosError(error) &&
      (
        error?.response?.data?.message ||
        error?.message
      ) ||
      'Произошла ошибка';
      throw (message);
    }
  }
};

export const mutations: MutationTree<RootState> = {
  SET_DEALS (state, payload: Array<Deal>): void {
    state.deals = payload;
  },

  SET_CURRENT_AGENT (state, payload): void {
    state.currentAgent = payload;
  }
};
