// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderProfileButton_U1UVW{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative}.HeaderProfileButton_U1UVW .userMenuButton_COkz9{padding:.45rem 2rem}.HeaderProfileButton_U1UVW:not(.menuOpen_z5MU\\+) .dropdownMenu_dC0-9{display:none}.HeaderProfileButton_U1UVW .chevron_j7HH9{-webkit-transform:rotate(0turn);transform:rotate(0turn);-webkit-transform-origin:center;transform-origin:center}.HeaderProfileButton_U1UVW.menuOpen_z5MU\\+ .chevron_j7HH9{-webkit-transform:rotate(.5turn);transform:rotate(.5turn)}.verticalFlex_3BERJ{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;gap:.4rem}.dropdownMenu_dC0-9{position:absolute;top:calc(100% + 1rem);width:-webkit-max-content;width:-moz-max-content;width:max-content}.agentName_g4Fcd{color:#012345;font-size:1.4rem;letter-spacing:-.01em;line-height:1.4;max-width:30ch;overflow:hidden;text-overflow:ellipsis;text-transform:capitalize;white-space:nowrap}@media only screen and (max-width:767.98px){.agentName_g4Fcd{max-width:33rem;width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderProfileButton": "HeaderProfileButton_U1UVW",
	"userMenuButton": "userMenuButton_COkz9",
	"menuOpen": "menuOpen_z5MU+",
	"dropdownMenu": "dropdownMenu_dC0-9",
	"chevron": "chevron_j7HH9",
	"verticalFlex": "verticalFlex_3BERJ",
	"agentName": "agentName_g4Fcd"
};
module.exports = ___CSS_LOADER_EXPORT___;
