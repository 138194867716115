var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _setup.toggle ? _vm.$style.show : _vm.$style.hide
  }, [_c(_setup.SButton, {
    class: _vm.$style.button,
    attrs: {
      "theme": "grey-slate",
      "equal": "",
      "size": "custom"
    },
    on: {
      "click": function click($event) {
        return _setup.scrollTo();
      }
    }
  }, [_c(_setup.SIcon, {
    attrs: {
      "icon": "arrow-up",
      "color": "#ffffff",
      "size": "24"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };