export const state = () => ({
  deals: [],
  count: 0,
  pageInfo: {},
  specs: {},
  ordering: [],
  lookup: {
    type: {},
    result: []
  },
  facets: {
    work_period: {},
    status: [],
    project: [],
    property_type: []
  },

  propertySpecs: []
});

export const actions = {
  async getDeals ({ commit, rootGetters }, params) {
    try {
      const { role } = rootGetters;
      const {
        dependentRole
      } = this.$api.account;
      const url = dependentRole.bookings('users', role);
      const {
        count,
        result,
        page_info: pageInfo
      } = await this.$axios.$get(url, { params });
      commit('SET_DEALS', result);
      commit('SET_COUNT', count);
      commit('SET_PAGE_INFO', pageInfo);
    } catch (error) {
      console.error('🚀 ~ file: deals.js ~ getDeals ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  async getSpecs ({ commit, rootGetters }) {
    try {
      const { role } = rootGetters;
      const {
        bookings
      } = this.$api.account;
      const url = bookings.specsV2(role);
      const {
        ordering,
        specs
      } = await this.$axios.$get(url);
      commit('SET_SPECS', specs);
      commit('SET_ORDERING', ordering);
    } catch (error) {
      console.error('🚀 ~ file: deals.js ~ getSpecs ~ error', error);
      this.$sentry.captureException(error);
    }
  },
  async getPropertySpecs ({ commit }) {
    try {
      const url = 'api/properties/type_specs';
      const {
        data
      } = await this.$axios.get(url);

      const specs = [
        {
          label: 'Все',
          value: undefined,
          meta: {}
        },
        ...data
      ];

      commit('SET_PROPERTY_SPECS', specs);
    } catch (error) {
      console.error('🚀 ~ file: deals.js ~ getPropertySpecs ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  async getFacets ({ commit, rootGetters }, params) {
    try {
      const {
        role
      } = rootGetters;
      const {
        bookings
      } = this.$api.account;
      const url = bookings.facets(role);
      const {
        facets
      } = await this.$axios.$get(url, { params });

      commit('SET_FACETS', facets);
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ ~ getFacets ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  async fetchLookup ({ commit, rootGetters }, params) {
    try {
      const { role } = rootGetters;
      const {
        bookings
      } = this.$api.account;
      const url = bookings.lookup(role);
      const lookup = await this.$axios.$get(url, { params });
      commit('SET_LOOKUP', lookup);
    } catch (error) {
      console.error('🚀 ~ file: deals.js ~ fetchLookup ~ error', error);
      this.$sentry.captureException(error);
    }
  },

  async nextPage ({ commit }, { page, infiniteState }) {
    try {
      const {
        result,
        page_info: pageInfo
      } = await this.$axios.$get(page.next_page);

      if (!pageInfo) {
        throw new Error('page info not received');
      }

      if (result?.length) {
        commit('SET_PAGE_INFO', pageInfo);
        commit('SET_NEXT_DEALS', result);

        if (!pageInfo.next_page) {
          infiniteState.complete();
        } else {
          infiniteState.loaded();
        }
      } else {
        infiniteState.loaded();
      }
    } catch (error) {
      console.log('🚀 ~ file: deals.js ~ ~ nextPage ~ error', error);
      this.$sentry.captureException(error);
      infiniteState.complete();
    }
  }
};

export const mutations = {
  SET_DEALS (state, payload) {
    state.deals = payload;
  },

  SET_NEXT_DEALS (state, payload) {
    state.deals = [...state.deals, ...payload];
  },

  SET_COUNT (state, payload) {
    state.count = payload;
  },

  SET_PAGE_INFO (state, payload) {
    state.pageInfo = payload;
  },

  SET_SPECS (state, payload) {
    state.specs = payload;
  },

  SET_FACETS (state, payload) {
    state.facets = payload;
  },

  SET_LOOKUP (state, payload) {
    state.lookup = payload;
  },

  SET_ORDERING (state, payload) {
    state.ordering = payload;
  },

  SET_PROPERTY_SPECS (state, payload) {
    state.propertySpecs = payload;
  }
};
